import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getOrders = createAsyncThunk('eCommerceApp/orders/getOrders', async (params) => {
  let urlparams = "";
  if (params?.pathname == "/apps/logs/wdms" || params?.pathname == "/apps/logs/wdms/filter") {
    urlparams = "0";
  } else if (params?.pathname == "/apps/logs/tdm" || params?.pathname == "/apps/logs/tdm/filter") {
    urlparams = "1";
  } else if (params?.pathname == "/apps/logs/visualApp" || params?.pathname == "/apps/logs/visualApp/filter") {
    urlparams = "2";
  }

  if (params?.user?.role == 'agency-admin') {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL_LOG}/api/logs/getall`, {
      app_type: urlparams,
      agency_id: params?.user?.data?.key.toString(),
      dataLimit: params?.dataLimit,
      sortPropert: params?.sortProperty,
      sortValue: params?.sortValue
    });

    const data = await response.data;
    return [data];

  } else {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL_LOG}/api/logs/getall`, {
      app_type: urlparams,
      dataLimit: params?.dataLimit,
      sortPropert: params?.sortProperty,
      sortValue: params?.sortValue
    });
    const data = await response.data;
    return [data];
  }
});

export const getOrdersFilter = createAsyncThunk('eCommerceApp/orders/filters', async (newData) => {
  var newdatas = {};
  Object.keys(newData?.filterData).forEach((ky) => {
    if (newData?.filterData[ky]) {
      newdatas[ky] = newData?.filterData[ky];
    }
  })
  let bodyData = {
    filter: newdatas,
    datalimit: newData?.dataLimit
  }
  const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL_LOG}/api/logs/getlogsbyfilter`, bodyData);
  const data = await response.data;
  return [data];
});


export const getAllAgencies = createAsyncThunk('getAllAgencies', async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Agency/getallIds`);
  const data = await response.data;
  return data?.staff?.data;
})

export const removeOrders = createAsyncThunk(
  'eCommerceApp/orders/removeOrders',
  async (orderIds, { dispatch, getState }) => {
    await axios.delete('/api/ecommerce/orders', { data: orderIds });
    return orderIds;
  }
);
export const getLogsById = createAsyncThunk('logs/getLogsById', async (objectId) => {
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL_LOG}/api/logs/getLogsById/${objectId}`);
  const data = await response.data;
  return data;
});

const ordersAdapter = createEntityAdapter({
  selectId: (state) => state.id
});

export const { selectAll: selectOrders, selectById: selectOrderById } = ordersAdapter.getSelectors(
  (state) => state.logs.orders
);


const ordersSlice = createSlice({
  name: 'logs/orders',
  initialState: ordersAdapter.getInitialState({
    searchText: '',
    filterStatus: false,
    filterData: {},
    // loadingsStatus: false
  }),
  reducers: {
    setOrdersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setFilterStatus: (state, action) => {
      state.filterStatus = action.payload
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload
    },
    // setLoadingStatus: (state, action) => {
    //   state.loadingStatus = action.payload
    // }
  },
  extraReducers: {
    [getOrders.fulfilled]: ordersAdapter.setAll,
    [getOrdersFilter.fulfilled]: ordersAdapter.setAll,
    [removeOrders.fulfilled]: (state, action) => ordersAdapter.removeMany(state, action.payload),
  },
});

export const { setOrdersSearchText, setFilterStatus, setFilterData, setLoadingStatus } = ordersSlice.actions;

// export const selectOrdersSearchText = ({ eCommerceApp }) => eCommerceApp.orders.searchText;

export const selectfilterStatus = ({ logs }) => logs.orders.filterStatus;
export const selectfilterData = ({ logs }) => logs.orders.filterData;
// export const selectLoadingStatus = ({ logs }) => logs.orders.loadingsStatus;
//dispatch(getLogsById(objectId));
export default ordersSlice.reducer;
