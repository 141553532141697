import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Autocomplete, TableFooter, TextField } from "@mui/material";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getAccountsByTags,
  getContacts,
  selectCollapseKey,
  selectContacts,
  selectTags,
  setCollapseKey,
} from "../../store/contactSlice";
import TablePagination from "@mui/material/TablePagination";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function ContactNumModel({ addContect, open, onClose, values }) {
  const [searchText, setSearchText] = useState("");
  const [filterdTagData, setFilterdTagData] = useState();
  const [searchFilteredTagData, setSearchFilteredTagData] = useState();
  const [isFilterdData, setIsFilterdData] = useState(false);
  const [contactsData, setContactsData] = useState();
  const [tagsData, setTagData] = useState({});
  const [tagNames, setTagNames] = useState({});

  const [filteredPageData, setFilteredPageData] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();

  const keyExist = useSelector(selectCollapseKey);

  useEffect(() => {
    dispatch(getAccountsByTags());
    dispatch(getContacts());
  }, [dispatch]);

  const newTags = useSelector(selectTags);
  const newContacts = useSelector(selectContacts);

  useEffect(() => {
    setTagData(newTags);
    const AllTagNames = newTags ? Object.keys(newTags) : [];
    setTagNames(AllTagNames);

    const contactInfo = [];
    if(newContacts != null){
    for (const innerArray of newContacts) {
      const contact = {
        Title: innerArray.Title || null,
        Phone: innerArray.Phone || null,
        Full_Name: innerArray.Full_Name || null,
      };
      contactInfo.push(contact);
    }
  
    setContactsData(contactInfo);
    setFilteredPageData(contactInfo);
  }
  }, [newTags, newContacts]);

  useEffect(() => {
    if (isFilterdData) {
      if (searchText) {
        const filteredData = {};

        for (const key in filterdTagData) {
          if (filterdTagData.hasOwnProperty(key)) {
            const records = filterdTagData[key][0]; // Access the first array within the key
            if (records.length > 0) {
              const filteredRecords = records.filter((record) => {
                // Customize the condition based on search criteria.
                return (
                  record?.Full_Name?.toLowerCase().includes(
                    searchText?.toLowerCase()
                  ) ||
                  record?.Designation?.toLowerCase().includes(
                    searchText?.toLowerCase()
                  ) ||
                  record?.Phone?.includes(searchText)
                );
              });

              if (filteredRecords.length > 0) {
                filteredData[key] = [filteredRecords];
              }
            }
          }
        }
        setSearchFilteredTagData(filteredData);
      } else {
        setSearchFilteredTagData(filterdTagData);
      }
    } else {
      if (searchText) {
        const filteredData = contactsData?.filter((contact) => {
          const searchLower = searchText?.toLowerCase();
          return (
            contact?.Full_Name?.toLowerCase().includes(searchLower) ||
            contact?.Title?.toLowerCase().includes(searchLower) ||
            contact?.Phone?.toLowerCase().includes(searchLower)
          );
        });
        setFilteredPageData(filteredData);
      } else {
        setFilteredPageData(contactsData);
      }
    }
  }, [searchText]);

  function createDataObject(filterdValue) {
    const data = {};

    filterdValue?.forEach(async (value) => {
      const key = value.Account_Name;

      if (!data[key]) {
        data[key] = [];
      }

      const newObjectData = value?.contacts?.map((contact) => {
        let obj = {};
        obj.Full_Name = contact.Full_Name;
        obj.Designation = contact?.Title == null ? null : contact?.Title;
        obj.Phone = contact?.Mobile == null ? null : contact?.Mobile;
        return obj;
      });
      data[key].push(newObjectData);
    });
    return data;
  }

  const specificTagData = (event, newValue) => {
    if (!newValue) {
      setIsFilterdData(false);
    } else {
      setSearchText("");
      setFilterdTagData([]);
      let filterdValue = tagsData[newValue];
      const tagDataObject = createDataObject(filterdValue);
      setFilterdTagData(tagDataObject);
      setSearchFilteredTagData(tagDataObject);
      setIsFilterdData(true);
    }
  };

  function Row(props) {
    const { row, organizationName, isOpened } = props;
    const [opens, setOpens] = React.useState(false);
    const dispatch = useDispatch();

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                if(keyExist == organizationName){
                dispatch(setCollapseKey(''))
                }else{
                dispatch(setCollapseKey(organizationName))   
                }
            }}
            >
              {isOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {organizationName || "Unknown Organization"}
          </TableCell>
        </TableRow>
        {row.map((individuals, index) => (
          <TableRow key={index}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={isOpened} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size="small" aria-label="contacts">
                    <TableHead>
                      <TableRow>
                        <TableCell>First Name</TableCell>
                        <TableCell>Designation</TableCell>
                        <TableCell align="right">Number</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    {individuals.length >= 1 ? (
                      <TableBody>
                        {individuals.map((individual) => (
                          <TableRow key={individual.Full_Name}>
                            <TableCell component="th" scope="row">
                              {individual.Full_Name}
                            </TableCell>
                            <TableCell>{individual.Designation}</TableCell>
                            <TableCell align="right">
                              {individual.Phone}
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                variant="contained"
                                color={
                                  values?.some(
                                    (obj) =>
                                      obj?.name === individual?.Full_Name &&
                                      obj?.phone == individual?.Phone &&
                                      obj?.designation ==
                                        individual?.Designation
                                  )
                                    ? "success"
                                    : "secondary"
                                }
                                size="small"
                                disabled={values?.some(
                                  (obj) =>
                                    obj?.name === individual?.Full_Name &&
                                    obj?.phone == individual?.Phone &&
                                    obj?.designation == individual?.Designation
                                )}
                                style={{
                                  backgroundColor:
                                    values?.some(
                                      (obj) =>
                                        obj?.name === individual?.Full_Name &&
                                        obj?.phone == individual?.Phone &&
                                        obj?.designation ==
                                          individual?.Designation
                                    ) && "#4caf50", // Success color code, adjust as necessary
                                  color: "white", // Adjust text color as necessary
                                }}
                                onClick={() =>{
                                dispatch(setCollapseKey(organizationName));
                                  addContect(
                                    individual.Phone,
                                    individual.Full_Name,
                                    individual.Designation
                                  )
                                }
                                  
                                }
                              >
                                <FuseSvgIcon size={20}>
                                  {values?.some(
                                    (obj) =>
                                      obj?.name === individual?.Full_Name &&
                                      obj?.phone == individual?.Phone &&
                                      obj?.designation ==
                                        individual?.Designation
                                  )
                                    ? "material-outline:check"
                                    : "heroicons-outline:plus"}
                                </FuseSvgIcon>
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody className="flex justify-center items-center">
                        No Contact Available
                      </TableBody>
                    )}
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ))}
      </React.Fragment>
    );
  }

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const handleClose = () => {
    // Reset local states
    setSearchFilteredTagData(null);
    setIsFilterdData(false);
    setSearchText("");
    // Close modal
    onClose();
  };
 

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Contacts
          </Typography>
          <div className="flex justify-between">
            <TextField
              label="Search"
              id="fullWidth"
              className="mt-2 mb-2"
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {Array.isArray(tagNames) && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={tagNames}
                sx={{ width: 300 }}
                onChange={specificTagData}
                size="small"
                renderInput={(params) => <TextField {...params} label="Tags" />}
              />
            )}
          </div>
          <TableContainer
            component={Paper}
            style={{ maxHeight: 400, overflow: "auto" }}
          >
            <Table
              aria-label="collapsible table"
              style={{ tableLayout: "auto" }}
            >
              <TableHead>
                {isFilterdData ? (
                  <TableRow>
                    <TableCell>Expand</TableCell>
                    <TableCell>Name</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell>Full_Name</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell align="right" className="ml-4">
                      Action
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              {isFilterdData ? (
                <TableBody>
                  {searchFilteredTagData &&
                    Object.entries(searchFilteredTagData)?.map(
                      ([key, value]) => (
                        <Row key={key} isOpened={keyExist == key ? true : false} row={value} organizationName={key} />
                      )
                    )}
                </TableBody>
              ) : (
                <>
                  <TableBody>
                    {filteredPageData
                      ?.slice(startIndex, endIndex)
                      .map((contact, index) => (
                        <TableRow className="w-full" key={index}>
                          <TableCell
                            style={{
                              maxWidth: "100px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {contact?.Full_Name}
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: "100px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {contact?.Title}
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: "100px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {contact?.Phone}
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              variant="contained"
                              color={
                                values?.some(
                                  (obj) =>
                                    obj?.name == contact?.Full_Name &&
                                    obj?.phone == contact?.Phone &&
                                    obj?.designation == contact?.Title
                                )
                                  ? "success"
                                  : "secondary"
                              }
                              size="small"
                              disabled={values?.some(
                                (obj) =>
                                  obj?.name == contact?.Full_Name &&
                                  obj?.phone == contact?.Phone &&
                                  obj?.designation == contact?.Title
                              )}
                              onClick={() =>{
                                addContect(
                                  contact.Phone,
                                  contact.Full_Name,
                                  contact.Title
                                )
                              }
                              }
                              style={{
                                backgroundColor:
                                  values?.some(
                                    (obj) =>
                                      obj?.name == contact?.Full_Name &&
                                      obj?.phone == contact?.Phone &&
                                      obj?.designation == contact?.Title
                                  ) && "#4caf50", // Success color code, adjust as necessary
                                color: "white", // Adjust text color as necessary
                              }}
                            >
                              <FuseSvgIcon size={20}>
                                {values?.some(
                                  (obj) =>
                                    obj?.name == contact?.Full_Name &&
                                    obj?.phone == contact?.Phone &&
                                    obj?.designation == contact?.Title
                                )
                                  ? "material-outline:check"
                                  : "heroicons-outline:plus"}
                              </FuseSvgIcon>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    count={contactsData ? contactsData.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
