import { useState, useCallback } from "react";
import axios from "axios";
import ChatBot from "react-chatbotify";

function ChatBotC() {
  const [messages, setMessages] = useState([]);

  const cleanHTML = (html) => {
    // Remove unwanted styles
    if (html) {
      return html.replace(/style="[^"]*"/g, '');
    }
  };

  const formStyle = {
    marginTop: 10,
    marginLeft: 20,
    marginBottom: 10, // Add this for vertical spacing between messages
    border: "1px solid #491d8d",
    padding: "10px 26px 10px 26px",
    borderRadius: 10,
    maxWidth: 300,
    backgroundColor: "#212529",
    color: "white",
  };

  const flow = {
    start: {
      message: "How may I help you?",
      function: async (params) => {
        const botResponse = await flow.fetchResponse(params.userInput);
        setMessages([
          { from: "user", message: params.userInput },
          { from: "bot", message: botResponse },
        ]);
        return botResponse;
      },
      path: "userInput",
    },
    userInput: {
    //   message: "result",
      component: (
        <>
          {messages.map((msg, index) => (
            msg.from === "bot" && (
              <div key={index} style={formStyle} dangerouslySetInnerHTML={{ __html: cleanHTML(msg.message) }} />
            )
          ))}
        </>
      ),
      function: async (params) => {
        const botResponse = await flow.fetchResponse(params.userInput);
        setMessages([
          { from: "user", message: params.userInput },
          { from: "bot", message: botResponse },
        ]);
        return botResponse;
      },
      path: "userInput",
    },
    fetchResponse: async (userQuery) => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_CHATBOT_BASE_URL,
          {
            mpm_query: userQuery,
          }
        );
        // Extract the relevant data from the response
        const data = response.data; // Assuming the response is an array with one object
        // Format the response into a user-friendly message
        const message = data;

        return message;
      } catch (error) {
        return "Sorry, I couldn't process your request.";
      }
    },
  };

  const handleUserInput = useCallback(
    async (input) => {
      const currentPath = messages.length
        ? messages[messages.length - 1].path
        : "start";
      const currentStep = flow[currentPath];

      if (currentStep.function) {
        await currentStep.function({ userInput: input });
      } else {
        const nextMessage = currentStep.message;
        setMessages((prevMessages) => [
          ...prevMessages,
          { from: "user", message: input },
          { from: "bot", message: nextMessage },
        ]);
      }
    },
    [messages, flow]
  );

  return (
    <>
      <ChatBot
        flow={flow}
        onUserInput={handleUserInput}
        messages={messages}
        styles={{
          botBubbleEntryStyle: {
            margin: "30px 30px",
          },
          botBubbleStyle: {
            maxWidth: "70%",
          },
          tooltipStyle: {
            zIndex: "9999",
            fontSize: "15px",
            // border: "1px solid #DCBB4D"
          },
          headerStyle: {},

          chatIconStyle: {
            width: "40px",
            height: "40px",
            position: "cover",
            // border: "1px solid #DCBB4D"
          },
          chatButtonStyle: {
             border: "1px solid #DCBB4D"
          }
        }}
        settings={{
          footer: { text: "Powered By MPMedia" },
          header: {
            showAvatar: true,
            avatar: "/assets/images/logo/activelogo.svg",
            title: (
              <div>
                <h3>Falcon AI</h3>
                <h6>Online</h6>
              </div>
            ),
          },
          notification: { disabled: true },
          emoji: { disabled: true },
          fileAttachment: { disabled: true },
          general: { primaryColor: "#212529", secondaryColor: "#212529" },

          userBubble: { showAvatar: true },
          botBubble: {
            showAvatar: true,
            avatar: "/assets/images/logo/logo2.svg",
          },
          chatButton: { icon: "/assets/images/logo/logo5.svg" },
        }}
      />
    </>
  );
}

export default ChatBotC;
