import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";

export default function SharedDiag({ status, OnhandleRemove, message }) {
  const [open, setOpen] = React.useState(status);

  const handleClose = () => {
    OnhandleRemove(false);
    setOpen(false);
  };
  useEffect(() => {
    setOpen(status);
  }, [status]);

  const handleAgree = () => {
    OnhandleRemove(true);
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-15">{message}</DialogTitle>
        <DialogActions>
          <Button
            className="border-2 border-solid px-24 py-20"
            onClick={handleClose}
          >
            Disagree
          </Button>
          <Button
            className="border-2 border-solid px-24 py-20"
            onClick={handleAgree}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
