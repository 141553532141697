import { lazy } from 'react';
import { authRoles } from 'src/app/auth';
import EditProfile from './tabs/EditProfile';

const UserProfileApp = lazy(() => import('./UserProfileApp'));

const UserProfileAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.allUser,
  routes: [
    {
      path: 'apps/userProfile',
      element: <UserProfileApp />,
    },
    {
      path: 'apps/userProfile/edit',
      element: <EditProfile />,
    },
  ],
};

export default UserProfileAppConfig;
