import React, { useEffect, useState } from 'react';
import { Alert, Box, IconButton, Menu, Snackbar, Tooltip, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import JsonItemIcon from "./JsonItemIcon";
import JsonTable from "./JsonTable";
import { MenuItem } from 'react-contextmenu';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReactJson from "react-json-view";
import { saveAs } from 'file-saver';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useDispatch } from 'react-redux';
import { deletedTdmJson } from '../../store/TransitSlice';
import sizeof from 'sizeof';
import ConfirmDialog from './ConfirmDialog';
import { showMessage } from 'app/store/fuse/messageSlice';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import CreateTdmStepper from './CreateTdmStepper';

function JsonFileItem({ item }) {
    const [myJson, setMyJson] = useState(item?.json || {});
    const [anchorEl, setAnchorEl] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [isDisabledConfirm, setIsDisabledConfirm] = useState(false);

    const [selectedItem, setSelectedItem] = useState(false);
    const [open, setOpen] = useState(false); // Initially set to false
    const [dialogOpen, setDialogOpen] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [snackMesaage, setSnackMesaage] = useState(null);
    const [snackVarient, setSnackVarient] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [color, setColor] = useState('primary');
    const [isCopied, setIsCopied] = useState(false);

    const dispatch = useDispatch()
    const params = useParams()

    const handleIconClick = (e) => {
        e.stopPropagation(); // Prevents triggering the parent onClick
        setOpen(true); // Set open to true when icon is clicked
    };

    const getTimestampName = () => {
        const timestamp = new Date(item?.createdAt).getTime() / 1000;
        setFileName(`${timestamp}_${item?.hexa_code}`)
    }

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleConfirmDelete = () => {
        setIsDisabledConfirm(true)
        let body = {
            tdmfile_id: params?.id,
            tdm_demo_json_id: item?.tdm_demo_json_id
        }
        dispatch(deletedTdmJson(body)).then((res) => {
            if (res?.payload?.response && res?.payload?.response?.status !== 200) {
                setSnackVarient("error")
                setSnackMesaage("TDM Json File Deleted")
                setDialogOpen(false);
                setSnackOpen(true)
                setIsDisabledConfirm(false)
            } else {
                setSnackVarient("success")
                setSnackMesaage("TDM Json File Deleted")
                setDialogOpen(false);
                setSnackOpen(true)
                setTimeout(() => {
                    setIsDisabledConfirm(false)
                    // handleClose();
                }, 2000)
            }
        })
    };

    useEffect(() => {
        setMyJson(item?.json || {})
    }, [item])

    function getJsonSize(json) {
        const jsonString = JSON.stringify(json);
        const sizeInBytes = new TextEncoder().encode(jsonString).length;
        const sizeInKB = sizeInBytes / 1024;
        return sizeInKB.toFixed(2);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        handleOpenDialog()
        handleClose()
    };

    const handleDownload = () => {
        const blob = new Blob([JSON.stringify(myJson, null, 2)], { type: 'application/json' });
        saveAs(blob, fileName);
        handleClose();
    };

    const handleView = () => {
        setOpen(true)
        handleClose();
    };

    const handleEdit = () => {
        setShowEditDialog(true)
    }

    useEffect(() => {
        getTimestampName()
    }, [])

    const handleCopy = (hexa_code) => {
        if (hexa_code) {
            let apiUrl = `${process.env.REACT_APP_API_BASE_URL}/api/tdm/demoJson?hexa_code=${hexa_code}`
            navigator.clipboard.writeText(apiUrl)
            setSnackVarient("success")
            setSnackMesaage("Copied to clipboard")
            setSnackOpen(true)
            setIsCopied(true)
        }
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    useEffect(() => {
        if (isCopied) {
            setColor('secondary');
            const timer = setTimeout(() => {
                setColor('primary');
                setIsCopied(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [isCopied]);

    return (
        <Box
            sx={{
                backgroundColor: item?.directoryId == selectedItem ? "#56595b" : "background.paper",
                width: "100%"
            }}
            className="min-w-md md:w-full h-72 md:h-64 m-8 p-16 shadow rounded-16 cursor-pointer"
        >
            <div className="flex flex-row justify-between w-full h-full">
                <div className="flex w-full">
                    <div className="flex flex-row justify-between w-full items-center gap-20">
                        <div className="flex items-center">
                            <JsonItemIcon size={35} onClick={handleIconClick} />
                            <Tooltip title="File Name">
                                <Typography className="truncate text-12 font-medium flex items-center">
                                    {fileName || null}
                                </Typography>
                            </Tooltip>
                        </div>

                        <div className="flex items-center justify-between space-x-12 ml-auto">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title="Hexa code">
                                    <Typography
                                        className="truncate text-12 font-medium border-2 rounded-12 px-12"
                                        style={{ borderColor: 'white' }}
                                    >
                                        {item?.hexa_code || ""}
                                    </Typography>
                                </Tooltip>
                                <Tooltip title="copy">
                                    <IconButton
                                        onClick={() => handleCopy(item?.hexa_code)}
                                        size="small"
                                        aria-label="copy to clipboard"
                                    >
                                        <ContentCopyIcon fontSize="small" color={color} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Tooltip title="Json Size">
                                <Typography className="truncate text-12 font-medium border-2 rounded-12 px-12" style={{ borderColor: "white" }}>
                                    <b>Size:</b> {getJsonSize(myJson?.data || {})} KB
                                </Typography>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title="Info">
                                <IconButton
                                    onClick={handleClick}
                                    style={{
                                        background: "gray",
                                    }}
                                >
                                    <FuseSvgIcon size={20} >
                                        heroicons-solid:information-circle
                                    </FuseSvgIcon>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <div className='flex flex-col gap-6 p-4 cursor-pointer px-10 py-5'>
                                    <MenuItem onClick={handleDelete}>
                                        <IconButton>
                                            <DeleteIcon color='secondary' fontSize="small" />
                                        </IconButton>
                                    </MenuItem>

                                    <MenuItem onClick={handleEdit}>
                                        <IconButton >
                                            <EditIcon color='success' fontSize="small" />
                                        </IconButton>
                                    </MenuItem>

                                    <MenuItem onClick={handleDownload}>
                                        <IconButton >
                                            <GetAppIcon color='primary' fontSize="small" />
                                        </IconButton>

                                    </MenuItem>
                                    <MenuItem onClick={handleView}>
                                        <IconButton >
                                            <VisibilityIcon color='disabled' fontSize="small" />
                                        </IconButton>
                                    </MenuItem>
                                </div>
                            </Menu>
                        </div>
                    </div>
                </div>
                <JsonTable myJson={myJson} open={open} setOpen={setOpen} fileName={fileName} />
            </div>
            <ConfirmDialog
                open={dialogOpen}
                handleClose={handleCloseDialog}
                handleConfirm={handleConfirmDelete}
                isDisabledConfirm={isDisabledConfirm}
            />
            <Snackbar
                open={snackOpen}
                autoHideDuration={2000}
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleCloseSnack} variant="filled" severity={snackVarient} >
                    {snackMesaage}
                </Alert>
            </Snackbar>
            {showEditDialog ? (
                <CreateTdmStepper showDialog={showEditDialog} setShowDialog={setShowEditDialog} myJson={myJson?.data} rowData={{ ...myJson?.jsonInfo, tdm_hexa_code: item?.hexa_code }} dialogType="edit" />
            ) : null}
        </Box>
    );
}

export default JsonFileItem;
