import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const Player = lazy(() => import('./player/Player'));
const Players = lazy(() => import('./players/Players'));
const HardwarePage = lazy(() => import('./player/HardwarePage'));

const DisplaysAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/displays',
      element: <Players />,
    },
    {
      path: 'apps/displays/:displaysId/*',
      element: <Player />,
    },
    {
      path: 'apps/displays/equip-logs/:type/:id',
      element: <HardwarePage />,
    }
  ],
};

export default DisplaysAppConfig;
