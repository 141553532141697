import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
import ContactModel from '../model/ApkKeysModel';
import firebaseService from 'src/app/services/firebaseService';
import { Navigate } from 'react-router-dom';

export const getContact = createAsyncThunk(
  'apkKeysApp/task/getContact',
  async (id, { dispatch, getState }) => {
    firebaseService.init(success => {
      if (!success) {
        resolve();
      }
    });

    return firebaseService.getApkKeysByID(id)
      .then((val) => {
        return val;
      })
      .catch(error => {
        console.log('Get Apk Key Error--->', error)
        dispatch(false)
      });
  }
);

export const addContact = createAsyncThunk(
  'apkKeysApp/contacts/addContact',
  async (contact, { dispatch, getState }) => {
    firebaseService.init(success => {
      if (!success) {
        resolve();
      }
    });

    return firebaseService.StoreData(contact)
      .then((val) => {
        return val;
      })
      .catch(error => {
        console.log('Store Apk Key Error--->', error)
        dispatch(false)
      });
  }
);

export const updateContact = createAsyncThunk(
  'apkKeysApp/contacts/updateContact',
  async (data, { dispatch, getState }) => {
    firebaseService.init(success => {
      if (!success) {
        resolve();
      }
    });
    return firebaseService.updateApkKeys(data)
      .then((val) => {
        return val;
      })
      .catch(error => {
        console.log('Get Apk Key Error--->', error)
        dispatch(false)
      });
  }

);

export const removeContact = createAsyncThunk(
  'apkKeysApp/contacts/removeContact',
  async (id, { dispatch, getState }) => {
    firebaseService.init(success => {
      if (!success) {
        resolve();
      }
    });

    return firebaseService.deleteApkKeys(id)
      .then((val) => {
        return val;
      })
      .catch(error => {
        console.log('Delete Apk Key Error--->', error)
        dispatch(false)
      });
  }
);

export const selectContact = ({ apkKeysApp }) => apkKeysApp.apkKey;

const apkKeySlice = createSlice({
  name: 'apkKeysApp/contact',
  initialState: null,
  reducers: {
    newContact: (state, action) => ContactModel(),
    resetContact: () => null,
  },
  extraReducers: {
    [addContact.fulfilled]: (state, action) => {
      return action;
    },
    [getContact.pending]: (state, action) => null,
    [getContact.fulfilled]: (state, action) => action.payload,
    [updateContact.fulfilled]: (state, action) => action.payload,
    [removeContact.fulfilled]: (state, action) => null,
  },
});

export const { resetContact, newContact } = apkKeySlice.actions;

export default apkKeySlice.reducer;
