import _ from '@lodash';

const AgencyModel = (data) =>
  _.defaults(data || {}, {
    username: '',
    firstname: '',
    lastname: '',
    role: 3,
    language: [],
    email: '',
    password: '',
    api_key: '',
    contacts: [{phone: '', name: '', designation: ''}],
    stop_api: '',
    route_api: '',
    vehicle_api: '',
    public_api: '',
  });

export default AgencyModel;
