import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const GtfsList = lazy(() => import('./gtfsList/GtfsList'));

const SwaggerGtfsAppConfig = {
    settings: {
        layout: {},
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/swagger-gtfs',
            element: <GtfsList />,
        },
        // {
        //     path: 'apps/gtfsList/gtfs/:gtfsId/*',
        //     element: <Gtfs />,
        // },
    ],
};

export default SwaggerGtfsAppConfig;
