import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { IconButton } from '@mui/material';

const ConfirmDialog = ({ open, handleClose, handleConfirm, isDisabledConfirm }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogContent className='pt-10 pb-32 py-20 flex flex-col justify-center items-center'>
                <div className="flex justify-end w-full">
                    <IconButton
                        size="large"
                        onClick={() => handleClose()}
                    >
                        <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
                    </IconButton>
                </div>
                <DialogTitle id="alert-dialog-title" sx={{ paddingTop: 0 }}>{"Confirm Deletion"}</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='gap-10'>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="primary" variant="contained" disabled={isDisabledConfirm}>
                        Delete
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog >
    );
};

export default ConfirmDialog;
