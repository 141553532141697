import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';

const phoneRegExp = "^(?:00|\\+)[0-9\\s.\\/-]{6,20}$";
const schema = yup.object().shape({
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    name: yup.string(),
    designation: yup.string(),
});

var defaultValues = {
    phone: '',
    name: '',
    designation: '',
};

function PhoneNumberInput(props) {
    const { value, hideRemove, key } = props;
    const [data, setData] = useState(value)

    const { control, formState, handleSubmit, reset } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    useEffect(() => {
        reset(value);
    }, [reset, value]);

    function onSubmit(data) {
        props.onChange(data);
    }


    return (
        data?.is_deleted === undefined || data.is_deleted === false ? (
            <form className="mb-32" onChange={handleSubmit(onSubmit)} key={props} >
                <div className='w-full mt-24' >
                    <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Contact"
                                placeholder="Contact"
                                variant="outlined"
                                fullWidth
                                error={!!errors.phone}
                                helperText={errors?.phone?.message}

                            />
                        )}
                    />
                </div>
                <div className='w-full mt-24'>
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                label="name"
                                placeholder="Name"
                                variant="outlined"
                                fullWidth
                                error={!!errors.name}
                                helperText={errors?.name?.message}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FuseSvgIcon size={20}>heroicons-solid:tag</FuseSvgIcon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                </div>
                <div className='w-full mt-24'>
                    <Controller
                        control={control}
                        name="designation"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                className=""
                                label="designation"
                                placeholder="Designation"
                                variant="outlined"
                                fullWidth
                                error={!!errors.designation}
                                helperText={errors?.designation?.message}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FuseSvgIcon size={20}>heroicons-solid:tag</FuseSvgIcon>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                </div>
                <div className='w-full mt-10 flex justify-end content-center'>
                    <div>
                        {!hideRemove && (
                            <div className='flex justify-center items-center'>
                                <IconButton
                                    style={{
                                        width: "32px",
                                        height: "32px"
                                    }}
                                    className='bg-red-700'
                                    onClick={(ev) => {
                                        ev.stopPropagation();
                                        props.onRemove(props.key);
                                    }}
                                >
                                    <FuseSvgIcon size={20}>heroicons-solid:trash</FuseSvgIcon>
                                </IconButton>
                            </div>
                        )}
                    </div>
                </div>
            </form>
        ) : null
    );
}

PhoneNumberInput.defaultProps = {
    hideRemove: false,
};

export default PhoneNumberInput;
