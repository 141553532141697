import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

// const Stop = lazy(() => import('./stop/Stop'));
const Api = lazy(() => import('./Api'));

const ApiAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/apis',
      element: <Api />,
    },
    // {
    //   path: 'apps/stops/:stopId/*',
    //   element: <Stop />,
    // },
  ],
};

export default ApiAppConfig;
