import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getAccountsByTags = createAsyncThunk(
  "agenciesApp/agency/getAccountsByTags",
  async (id, { dispatch, getState }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/zohocrm/get-zoho-accounts-by-tags`
      );
      const data = await response?.data?.data;
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

export const getContacts = createAsyncThunk(
  "agenciesApp/agency/getContacts",
  async (id, { dispatch, getState }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/zohocrm/get-all-zoho-contacts`
      );
      const data = await response?.data?.data;
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
);

const contactSlice = createSlice({
  name: "agenciesApp/contacts",
  initialState: {
    contacts: [],
    tags: {},
    collapseKey:"", 
  },
  reducers: {
    setCollapseKey:(state, action) => {
        state.collapseKey = action.payload
    }
  },
  extraReducers: {
    [getAccountsByTags.pending]: (state, action) => state,
    [getAccountsByTags.fulfilled]: (state, action) => {
      state.tags = action.payload;
    },
    [getContacts.pending]: (state, action) => state,
    [getContacts.fulfilled]: (state, action) => {
      state.contacts = action.payload;
    },
  },
});

export const selectTags = ({ agenciesApp }) => agenciesApp.contacts.tags;
export const selectContacts = ({ agenciesApp }) => agenciesApp.contacts.contacts;
export const selectCollapseKey = ({ agenciesApp }) => agenciesApp.contacts.collapseKey;

export const {setCollapseKey} = contactSlice.actions;

export default contactSlice.reducer;
