// const prodConfig = {
// 	// apiKey           : "YOUR_API_KEY",
// 	// authDomain       : "your-app.firebaseapp.com",
// 	// databaseURL      : "https://your-app.firebaseio.com",
// 	// projectId        : "your-app",
// 	// storageBucket    : "your-app.appspot.com",
// 	// messagingSenderId: "YOUR_MESSAGING_SENDER_ID"
// };

// const devConfig = {
// 	// apiKey           : "YOUR_API_KEY",
// 	// authDomain       : "your-app.firebaseapp.com",
// 	// databaseURL      : "https://your-app.firebaseio.com",
// 	// projectId        : "your-app",
// 	// storageBucket    : "your-app.appspot.com",
// 	// messagingSenderId: "YOUR_MESSAGING_SENDER_ID"
// };

// const config = {
// 	apiKey: 'AIzaSyCkUYJ8rrh4xdJPCy1M1Pjj9EbtvRiZbMY',
// 	authDomain: 'test-package-manager-7e15e.firebaseapp.com',
// 	databaseURL: 'https://test-package-manager-7e15e-default-rtdb.firebaseio.com',
// 	projectId: 'test-package-manager-7e15e',
// 	storageBucket: 'test-package-manager-7e15e.appspot.com',
// 	messagingSenderId: '271757241289',
// 	appId: '1:271757241289:web:17deab8e9429b72184d618',
// 	measurementId: 'G-VZDLR22XSK',
// };

const config = {
	apiKey: "AIzaSyAviNzaeDsYtKCat0ro8tFdGnh7arCtQhI",
	authDomain: "mpm-monitoring.firebaseapp.com",
	databaseURL: "https://mpm-monitoring-default-rtdb.firebaseio.com",
	projectId: "mpm-monitoring",
	storageBucket: "mpm-monitoring.appspot.com",
	messagingSenderId: "28844715663",
	appId: "1:28844715663:web:bd2427ef04272bf0af4afa"

	
  };

// const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

export default config;
