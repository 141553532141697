import * as React from 'react';
import Button from '@mui/material/Button';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FuseLoading from '@fuse/core/FuseLoading';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Box from '@mui/system/Box';
import format from 'date-fns/format';
import _ from '@lodash';
import { getContact, selectContact } from '../store/apkKeySlice';
import { selectCountries } from '../store/countriesSlice';
import { selectTags } from '../store/tagsSlice';
import { useState } from 'react';

const ApkKeysView = () => {
  const routeParams = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContact(routeParams.id));
  }, [dispatch, routeParams]);

  const contact = useSelector(selectContact);

  var items = null;

  if (contact) {
    items = Object.values(contact);
    if (routeParams.id !== 'new') {
      if (!items[1]) {
        return null;
      }
    }
  }
  else {
    return null;
  }

  if (!contact) {
    return <FuseLoading />;
  }

  return (
    <>
      <Box
        className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
        sx={{
          backgroundColor: 'background.default',
        }}
      >
      </Box>
      <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
        <div className="w-full max-w-3xl">
          <div className="flex flex-auto items-end -mt-64">
            <Avatar
              sx={{
                borderWidth: 4,
                borderStyle: 'solid',
                borderColor: 'background.paper',
                backgroundColor: 'background.default',
                color: 'text.secondary',
              }}
              className="w-128 h-128 text-64 font-bold"
            >
            </Avatar>
            <div className="flex items-center ml-auto mb-4">
              <Button variant="contained" color="secondary" component={NavLinkAdapter} to="edit">
                <FuseSvgIcon size={20}>heroicons-outline:pencil-alt</FuseSvgIcon>
                <span className="mx-8">Edit</span>
              </Button>
            </div>
          </div>

          <Typography className="mt-12 text-4xl font-bold truncate">{routeParams.id}</Typography>

          <Divider className="mt-16 mb-24" />
          {(items[0]) && (items[1].length) && (items[2]) ?
            <div className="flex flex-col space-y-32">
              <div className="flex">
                <FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>
                <div className="min-w-0 ml-24 space-y-4">
                  <div className="flex items-center leading-6">
                    {items[2].label}
                    <>
                      <Typography className="text-md truncate" color="text.secondary">
                        <span className="mx-8">&bull;</span>
                        <span className="font-medium">Provider</span>
                      </Typography>
                    </>
                  </div>
                </div>
              </div>
              <div className="flex">
                <FuseSvgIcon>heroicons-outline:user</FuseSvgIcon>
                <div className="min-w-0 ml-24 space-y-4">
                  <div className="flex items-center leading-6">
                    {items[0].label}
                    <>
                      <Typography className="text-md truncate" color="text.secondary">
                        <span className="mx-8">&bull;</span>
                        <span className="font-medium">Agency</span>
                      </Typography>
                    </>
                  </div>
                </div>
              </div>
              <div className="flex">
                <FuseSvgIcon className="text-48" size={24} color="action">heroicons-outline:clipboard-list</FuseSvgIcon>
                <div className="min-w-0 ml-24 space-y-4">
                  <div className="leading-6">
                    Apks
                    {items[1].map(apks => {
                      return (
                        <div key={apks} className='mt-10'>
                          <Typography key={apks} className="text-md truncate" color="text.secondary">
                            <span className="mx-8">&bull;</span>
                            <span key={apks} className="font-medium">{apks}</span>
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            : ''}
        </div>
      </div>
    </>
  );
};

export default ApkKeysView;
