import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const seedDataBase = createAsyncThunk(
    'transitDataManagerApp/seed/database',
    async ({ body, type }) => {
        try {
            let response;

            if (type === 'zip') {
                const formData = new FormData();
                formData.append('agency_key', body.agency_key);
                formData.append('gtfs_file', body.gtfs_file);
                formData.append('uploaded_by', body.uploaded_by);
                formData.append('type', body.type);

                response = await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL_TDM_CREATOR}/tdmCreatorMiddleWare/api/v1/tdm/seed/database`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
            } else if (type === 'url') {
                const requestData = {
                    agency_key: body?.agency_key,
                    gtfsrt_static_url: body?.gtfsrt_static_url,
                    uploaded_by: body?.uploaded_by,
                    type: body?.type
                };
                response = await axios.post(`${process.env.REACT_APP_API_BASE_URL_TDM_CREATOR}/tdmCreatorMiddleWare/api/v1/tdm/seed/database`, requestData);
            } else {
                const requestData = {
                    agency_key: body?.agency_key,
                    uploaded_by: body?.uploaded_by,
                    type: body?.type
                };
                response = await axios.post(`${process.env.REACT_APP_API_BASE_URL_TDM_CREATOR}/tdmCreatorMiddleWare/api/v1/tdm/seed/database`, requestData);
            }

            return response?.data;
        } catch (error) {
            return error;
        }
    }
);

export const getGtfsAgencies = createAsyncThunk(
    'transitDataManagerApp/gtfs/agencies',
    async (body) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/agencyTable/getGtfsAgencies?role=${body?.role}&user_id=${body?.user_id}`);
            // const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/agency_configs/getAll`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }
);

export const seedExpiredDb = createAsyncThunk(
    'transitDataManagerApp/seed/expired/database',
    async (body) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL_TDM_CREATOR}/tdmCreatorMiddleWare/api/v1/tdm/seed/expired/database`, body);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }
);

export const getTdmFiles = createAsyncThunk(
    'transitDataManagerApp/gtfs/getTdmFiles',
    async (body) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/tdm/getTdmJsonFile?role=${body?.role}&user_id=${body?.user_id}&gtfs_agencies=${body?.gtfs_agencies}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }
);

export const getTdmFileById = createAsyncThunk(
    'transitDataManagerApp/gtfs/getTdmFiles/byId',
    async (body) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/tdm/getTdmJsonFile/${body?.id}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }
);


export const getAllRoutes = createAsyncThunk(
    'transitDataManagerApp/gtfs/getTdmRouteData',
    async (body) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL_TDM_CREATOR}/tdmCreatorMiddleWare/api/v1/tdm/route/all?agency_key=${body?.agency_key}&hexacode=${body?.hexacode}&type=${body?.type}`);
            return response?.data;
        } catch (error) {
            return error;
        }
    }
);

export const getCurrentTrips = createAsyncThunk(
    'transitDataManagerApp/gtfs/getCurrentTrips',
    async (body) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL_TDM_CREATOR}/tdmCreatorMiddleWare/api/v1/tdm/trips/current?agency_key=${body?.agency_key}&hexacode=${body?.hexacode}&routeId=${body?.routeId}&type=${body?.type}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }
);

export const getRouteInfo = createAsyncThunk(
    'transitDataManagerApp/gtfs/getRouteInfo',
    async (body) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL_TDM_CREATOR}/tdmCreatorMiddleWare/api/v1/tdm/route/info`, {
                params: {
                    agency_key: body?.agency_key,
                    hexacode: body?.hexacode,
                    routeId: body?.routeId,
                    directionId: body?.directionId,
                    operator: body?.operator,
                    tripId: body?.tripId,
                    type: body?.type,
                    stopIds: body?.stopIds,
                    stopNames: body?.stopNames,
                    routeLongName: body?.routeLongName
                }
            });
            return response?.data;
        } catch (error) {
            return error;
        }
    }
);


export const getStopsByTripId = createAsyncThunk(
    'transitDataManagerApp/gtfs/getStopsByTripId',
    async (body) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL_TDM_CREATOR}/tdmCreatorMiddleWare/api/v1/tdm/stops/byTripId?agency_key=${body?.agency_key}&hexacode=${body?.hexacode}&routeId=${body?.routeId}&directionId=${body?.directionId}&operator=${body?.operator}&tripId=${body?.tripId}&type=${body?.type}`);
            return response?.data;
        } catch (error) {
            return error;
        }
    }
);

export const extendTdmFileExpiry = createAsyncThunk(
    'transitDataManagerApp/gtfs/extendTdmFileExpiry',
    async (body) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/tdm/extend-expiry`, body);
            return response?.data;
        } catch (error) {
            return error;
        }
    }
);

export const saveTdmJson = createAsyncThunk(
    'transitDataManagerApp/gtfs/tdm/json/save',
    async (body) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/tdm/demoJson/save`, body);
            return response?.data;
        } catch (error) {
            return error;
        }
    }
);

export const updateTdmJson = createAsyncThunk(
    'transitDataManagerApp/gtfs/tdm/json/save',
    async (body) => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/tdm/demoJson/update`, body);
            return response?.data;
        } catch (error) {
            return error;
        }
    }
);

export const getAllTdmJsons = createAsyncThunk(
    'transitDataManagerApp/gtfs/tdm/json/getAll',
    async (body) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/tdm/demoJson/getAll?tdmfile_id=${body?.tdmfile_id}`);
            return response?.data;
        } catch (error) {
            return error;
        }
    }
);

export const deletedTdmJson = createAsyncThunk(
    'transitDataManagerApp/gtfs/tdm/json/delete',
    async (body) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/tdm/demoJson/delete?tdm_demo_json_id=${body?.tdm_demo_json_id}&tdmfile_id=${body?.tdmfile_id}`);
            return response?.data;
        } catch (error) {
            return error;
        }
    }
);



const TransitSlice = createSlice({
    name: 'transitDataManagerApp',
    initialState: {
        loading: false,
        error: null,
        data: null,
        tdmFiles: [],
        tdmRowData: {},
        tdmRouteData: [],
        tdmTripData: [],
        tdmDemoJsonData: {},
        gtfsAgencies: [],
        tdmJsons: [],
        stopsByTrip: [],
        tdmJson: {},
        searchText: '',
        hightlightedRow: null,
    },
    reducers: {
        seTdmRowtData(state, action) {
            state.tdmRowData = action.payload;
        },
        setFilesSearchText(state, action) {
            state.searchText = action.payload;
        },
        setTdmJsonDefault(state, action) {
            state.tdmJsons = [];
        },
        setHighlightedRow(state, action) {
            state.hightlightedRow = action.payload;
        },
        setStopsDataByTripId(state, action) {
            state.stopsByTrip = action.payload;
        }
    },
    extraReducers: {
        [seedDataBase.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        [seedDataBase.fulfilled]: (state, action) => {
            state.data = action.payload;
        },
        [seedDataBase.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [seedExpiredDb.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
        },
        [seedExpiredDb.fulfilled]: (state, action) => {
            state.data = action.payload;
        },
        [seedExpiredDb.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [getGtfsAgencies.pending]: (state, action) => {
        },
        [getGtfsAgencies.fulfilled]: (state, action) => {
            state.data = action.payload?.gtfsAgencies;
        },
        [getGtfsAgencies.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [getTdmFiles.pending]: (state, action) => {
            state.error = null;
        },
        [getTdmFiles.fulfilled]: (state, action) => {
            state.tdmFiles = action.payload?.data;
        },
        [getTdmFiles.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [getAllRoutes.pending]: (state, action) => {
            state.error = null;
        },
        [getAllRoutes.fulfilled]: (state, action) => {
            state.tdmRouteData = action.payload?.data;
        },
        [getAllRoutes.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [getCurrentTrips.pending]: (state, action) => {
            state.error = null;
        },
        [getCurrentTrips.fulfilled]: (state, action) => {
            state.tdmTripData = action.payload?.tripInfo;
        },
        [getCurrentTrips.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [getRouteInfo.pending]: (state, action) => {
            state.error = null;
        },
        [getRouteInfo.fulfilled]: (state, action) => {
            state.tdmDemoJsonData = action.payload?.tripInfo;
        },
        [getRouteInfo.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [extendTdmFileExpiry.pending]: (state, action) => {
            state.error = null;
        },
        [extendTdmFileExpiry.fulfilled]: (state, action) => {
            state.data = action.payload;
        },
        [extendTdmFileExpiry.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [getTdmFileById.pending]: (state, action) => {
            state.error = null;
        },
        [getTdmFileById.fulfilled]: (state, action) => {
            state.tdmRowData = action?.payload?.data || {};
        },
        [getTdmFileById.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [getAllTdmJsons.pending]: (state, action) => {
            state.error = null;
        },
        [getAllTdmJsons.fulfilled]: (state, action) => {
            state.tdmJsons = action?.payload?.data || [];
        },
        [getAllTdmJsons.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [saveTdmJson.pending]: (state, action) => {
            state.error = null;
        },
        [saveTdmJson.fulfilled]: (state, action) => {
            state.tdmJsons = action?.payload?.data || [];
        },
        [saveTdmJson.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [updateTdmJson.fulfilled]: (state, action) => {
            state.tdmJsons = action?.payload?.data || [];
        },
        [deletedTdmJson.pending]: (state, action) => {
            state.error = null;
        },
        [deletedTdmJson.fulfilled]: (state, action) => {
            state.tdmJsons = action?.payload?.data || [];
        },
        [deletedTdmJson.rejected]: (state, action) => {
            state.error = action.error.message;
        },
        [getStopsByTripId.pending]: (state, action) => {
            state.error = null;
        },
        [getStopsByTripId.fulfilled]: (state, action) => {
            state.stopsByTrip = action?.payload?.data || [];
        },
        [getStopsByTripId.rejected]: (state, action) => {
            state.error = action.error.message;
        },
    },
});

export const selectTransitSliceData = ({ transitDataManagerApp }) => transitDataManagerApp?.TransitSlice
export const selectTransitRowSliceData = ({ transitDataManagerApp }) => transitDataManagerApp?.TransitSlice?.tdmRowData
export const selectTransitTripSliceData = ({ transitDataManagerApp }) => transitDataManagerApp?.TransitSlice?.tdmTripData
export const selectGtfsAgecniesData = ({ transitDataManagerApp }) => transitDataManagerApp?.TransitSlice?.getGtfsAgencies
export const selectSearchTextData = ({ transitDataManagerApp }) => transitDataManagerApp?.TransitSlice?.searchText
export const selectTdmJsonsData = ({ transitDataManagerApp }) => transitDataManagerApp?.TransitSlice?.tdmJsons
export const selectHighlightedRow = ({ transitDataManagerApp }) => transitDataManagerApp?.TransitSlice?.hightlightedRow
export const selectStopsByTripData = ({ transitDataManagerApp }) => transitDataManagerApp?.TransitSlice?.stopsByTrip
export const { seTdmRowtData, setFilesSearchText, setTdmJsonDefault, setHighlightedRow, setStopsDataByTripId } = TransitSlice.actions;

export default TransitSlice.reducer;
