import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
import AgencyModel from '../model/AgencyModel';
 
export const getAgency = createAsyncThunk(
  'agenciesApp/task/getAgency',
  async (id, { dispatch, getState }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Agency/get/${id}`);
      const data = await response.data;
      return data.staff.data[0];
    } catch (error) {
      history.push({ pathname: `/apps/agencies` });
 
      return null;
    }
  }
);
 
export const getAllProvider = createAsyncThunk(
  'providersApp/providers/getAllProviders',
  async (params, { getState }) => {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Provider/getall`);
    const data = await response.data;
    const originalData = [];
    data?.Providers?.data?.map((providerData => {
            originalData.push(
            {
                'value': parseInt(providerData?.provider_id),
                'label': providerData?.name
            })
    }))
    return originalData;
  }
);
 
// export const getProviderById = createAsyncThunk(
//   'providersApp/task/getContact',
//   async (id, { dispatch, getState }) => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Provider/get/${id}`);
//       const data = await response.data;
//       return data.data.data;
//     } catch (error) {
//       history.push({ pathname: `/apps/providers` });
//       return null;
//     }
//   }
// );
 
export const addAgency = createAsyncThunk(
  'agenciesApp/agencies/addAgency',
  async (agency, { dispatch, getState }) => {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/Agency/create`, agency);
    const data = await response.data;
    data.staff.data['users'] = {
      username: agency.username
    };
     return data;
  }
);
 
export const updateAgency = createAsyncThunk(
  'agenciesApp/agencies/updateAgency',
  async (agency, { dispatch, getState }) => {
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/Agency/update`, agency);
    const data = await response.data;
    data.Agency['users'] = {
      username: agency.username
    }
    return data?.Agency?.data;
  }
);
 
export const removeAgency = createAsyncThunk(
  'agenciesApp/agencies/removeAgency',
  async (id, { dispatch, getState }) => {
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/agency/remove`, id);
    await response.data;
    return id;
  }
);
 
export const AgencyAccount = createAsyncThunk(
  'agenciesApp/agencies/AgencyAccount',
  async (agencyaccountdata, { dispatch, getState }) => {
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/agency/AgencyAccount`, agencyaccountdata);
    let data = response?.data;
    return data;
  }
);
 
export const selectAgency = ({ agenciesApp }) => agenciesApp.agency;
 
const agencySlice = createSlice({
  name: 'agenciesApp/agency',
  initialState: null,
  reducers: {
    newAgency: (state, action) => AgencyModel(),
    resetAgency: () => null,
  },
  extraReducers: {
    [getAgency.pending]: (state, action) => null,
    [getAgency.fulfilled]: (state, action) => action.payload,
    [updateAgency.fulfilled]: (state, action) => action.payload,
    [removeAgency.fulfilled]: (state, action) => null,
    [AgencyAccount.fulfilled]: (state, action) => null,
  },
});
 
export const { resetAgency, newAgency } = agencySlice.actions;
 
export default agencySlice.reducer;