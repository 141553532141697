import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { amber, blue, green, grey, red } from '@mui/material/colors';

const TypeBadge = styled(Box)(({ theme, ...props }) => ({
  backgroundColor: {
    undefined: red[600],
    PDF: red[600],
    DOC: blue[600],
    XLS: green[600],
    TXT: grey[600],
    JPG: amber[600],
  }[props.color],
}));

function JsonItemIcon({ size, onClick }) {
  return (
    <div className="relative" onClick={onClick}>
      <FuseSvgIcon className="" size={size} color="disabled">
        heroicons-outline:document
      </FuseSvgIcon>
      <TypeBadge
        color="undefined"
        className="absolute left-0 bottom-0 px-4 w-[25px] h-[12px] rounded text-[8px] font-semibold leading-20 text-white"
      >
        Json
      </TypeBadge>
    </div>
  );
}

export default JsonItemIcon;

