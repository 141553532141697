import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const Player = lazy(() => import('./player/Player'));
const Players = lazy(() => import('./players/Players'));

const PlayersAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/players',
      element: <Players />,
    },
    {
      path: 'apps/players/:playerId/*',
      element: <Player />,
    }
  ],
};

export default PlayersAppConfig;
