import Button from "@mui/material/Button";
import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import FuseLoading from "@fuse/core/FuseLoading";
import _ from "@lodash";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import Box from "@mui/system/Box";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import { MobileDateRangePicker } from "@mui/lab";
import { DateRangePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { getOrdersFilter } from "../../../store/ordersSlice";
import { useState } from "react";
import { ConstructionOutlined } from "@mui/icons-material";
import { getAllAgencies, setFilterData, setFilterStatus } from "../../../store/ordersSlice";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  packageManager: yup
    .string()
    .lowercase()
    .required("You must enter a Package Manager key"),
});

/**
 * Default values for provider and will be update once the API will be updated
 */
const providersOptions = [
  {
    value: "1",
    label: "Avail",
  },
  {
    value: "2",
    label: "TripSpark",
  },
  {
    value: "3",
    label: "MPM",
  },
];

const Options = [
  {
    value: "error",
    label: "Error",
  },
  {
    value: "warning",
    label: "Warning",
  },
  {
    value: "info",
    label: "Info",
  },
];


const LogForm = (props) => {
  const routeParams = useParams();
  const dispatch = useDispatch();
  //   const navigate = useNavigate();

  const { control, watch, reset, handleSubmit, formState, setValue, trigger } =
    useForm({
      mode: "onChange",
      // resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  const [DialogStatus, setDialogStatus] = useState(false);
  const [datevalue, setdateValue] = useState([null, null]);
  const [AllAgencies, setAllAgencies] = useState([]);

  useEffect(() => {
    dispatch(getAllAgencies()).then((res) => {
      setAllAgencies(res?.payload);
    });
  }, []);

  /**
   * Form Submit
   */

  function onSubmit(data) {
    var newData = {};
    Object.keys(data).filter((d) => {
      if (data[d] != undefined) {
        newData[d] = data[d];
      }
    })
    Object.keys(data).filter((d) => {
      if (data[d]) {
        newData[d] = data[d];
      }
    })
    var typeApp = "";
    if (window.location.pathname == '/apps/logs/wdms/filter') {
      typeApp = 'WDM'
    } else if (window.location.pathname == '/apps/logs/tdm/filter') {
      typeApp = 'TDM'
    } else if (window.location.pathname == '/apps/logs/visualApp/filter') {
      typeApp = 'VAP'
    };
    if (newData['error_type']) {
      if (newData['error_type'].length == 0) {
        delete newData['error_type']
      } else {
        const t = newData['error_type'];
        newData['error_type'] = t['value']
      }
    }
    if (newData['$gte'] && newData['$lte']) {
      newData['createdAt'] = {
        '$gte': newData['$gte'],
        '$lte': newData['$lte']
      }
    } else if (newData['$gte'] && newData['$lte'] == undefined) {
      var datenew = new Date();
      var month = datenew.getMonth() + 1;
      newData['createdAt'] = {
        '$gte': newData['$gte'],
        '$lte': `${datenew.getFullYear()}-${month}-${datenew.getDate()}`
      }

    } else if (newData['$gte'] == undefined && newData['$lte']) {
      var datenew = new Date();
      var month = datenew.getMonth() + 1;
      newData['createdAt'] = {
        '$gte': `${datenew.getFullYear()}-${month}-${datenew.getDate()}`,
        '$lte': newData['$lte']
      }
    }
    delete newData['$gte'];
    delete newData['$lte'];
    newData['app_type'] = typeApp;

    dispatch(setFilterData(newData));
    dispatch(setFilterStatus(true))
    let filterLogs = {
      filterData: newData,
      dataLimit: 10
    }
    dispatch(getOrdersFilter(filterLogs));
  }

  function handleRemoveContact(StatusAgree) {
    // if (StatusAgree) {
    //   dispatch(removeContact(routeParams.id)).then(() => {
    //     navigate('/apps/apk-keys-managment');
    //     dispatch(getApkKeys());
    //   });
    // } else {
    //   navigate('/apps/apk-keys-managment');
    //   dispatch(getApkKeys());
  }

  //   if (_.isEmpty(form) || !contact) {
  //     return <FuseLoading />;
  //   }
  const [dvalue, setdValue] = useState("2014-08-18T21:11:54");

  const handleChange = (newValue) => {
    setdValue(newValue);
  };

  return (
    <>
      {/* <Box
        className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
        sx={{
          backgroundColor: "background.default",
        }}
      ></Box> */}
      {/* <div className="w-68 h-32">
        <MobileDateRangePicker
          label="Date mobile"
          inputFormat="MM/DD/YYYY"
          value={dvalue}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        /> */}
      {/* <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    localeText={{ start: 'Check-in', end: 'Check-out' }}
                    >
                    <DateRangePicker
                        startText=""
                        endText=""
                        value={datevalue}
                        inputFormat="dd,MMM"
                        onChange={(newValue) => {
                        setdateValue(newValue);
                        }}
                        renderInput={(startProps, endProps) => (
                        <>
                            <Box sx={{ mx: 2 }}> From </Box>
                            <TextField {...startProps} />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextField {...endProps} />
                        </>
                        )}
                    />
                    </LocalizationProvider> */}
      {/* </div> */}

      <div className="flex flex-col flex-auto items-center px-24 sm:px-48 overflow-auto h-512">

        <Controller
          name="agency_id"
          control={control}
          defaultValue={[]}
          render={({ field: { onChange, value, onBlur, ref } }) => (
            <Autocomplete
              className="mt-32"
              freeSolo
              options={AllAgencies}
              value={value.value}
              fullWidth
              onChange={(event, newValue) => {
                onChange(newValue?.label);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Agency Id"
                  label="Agencies"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.Autocomplete}
                  helperText={errors?.Autocomplete?.message}
                  onBlur={onBlur}
                  inputRef={ref}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="player_id"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="Player Id"
              placeholder="Player Id"
              id="player_id"
              error={!!errors.packageManager}
              helperText={errors?.packageManager?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="app_id"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="App Id"
              placeholder="App Id"
              id="app_id"
              error={!!errors.packageManager}
              helperText={errors?.packageManager?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="app_version"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="App Version"
              placeholder="App Version"
              id="app_version"
              error={!!errors.packageManager}
              helperText={errors?.packageManager?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="app_name"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="App Name"
              placeholder="App Name"
              id="app_name"
              error={!!errors.packageManager}
              helperText={errors?.packageManager?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          name="error_type"
          control={control}
          defaultValue={[]}
          render={({ field: { onChange, value, onBlur, ref } }) => (
            <Autocomplete
              className="mt-32"
              // freeSolo
              options={Options}
              value={value}
              fullWidth
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Error Type"
                  label="Error Type"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.Autocomplete}
                  helperText={errors?.Autocomplete?.message}
                  onBlur={onBlur}
                  inputRef={ref}
                />
              )}
            />
          )}
        />

        <Controller
          control={control}
          name="$gte"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="From"
              type={'date'}
              format={"YYYY MMMM DD"}
              placeholder="From Date"
              id="$gte"
              error={!!errors.packageManager}
              helperText={errors?.packageManager?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Controller
          control={control}
          name="$lte"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="To"
              type={'date'}
              format={"YYYY MMMM DD"}
              placeholder="To Date"
              id="$lte"
              error={!!errors.packageManager}
              helperText={errors?.packageManager?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>
                      heroicons-solid:user-circle
                    </FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </div>
      <Box
        className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t"
        sx={{ backgroundColor: "background.default" }}
      >
        <Button className="ml-auto" component={NavLinkAdapter} to={-1}>
          Cancel
        </Button>
        <Button
          className="ml-8"
          variant="contained"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Filter
        </Button>
      </Box>
    </>
  );
};

export default LogForm;
