import themesConfig from 'app/configs/themesConfig';
import i18n from '../../i18n';

const settingsConfig = {
  layout: {
    style: 'layout1', // layout1 layout2 layout3
    config: {
      navbar: {
        display: true,
        style: 'style-1',
        folded: true,
        position: 'left',
      },
      footer: {
        display: false,
      },
      rightSidePanel: {
        display: false,
      },
      settingsPanel: {
        display: false,
      }
    }, // checkout default layout configs at app/theme-layouts for example  app/theme-layouts/layout1/Layout1Config.js
  },
  customScrollbars: true,
  direction: i18n.dir(i18n.options.lng) || 'ltr', // rtl, ltr
  theme: {
    main: themesConfig.dark7,
    navbar: themesConfig.dark7,
    toolbar: themesConfig.dark7,
    footer: themesConfig.dark7,
  },
  /*
   To make whole app auth protected by default set defaultAuth:['admin','provider','user']
   To make whole app accessible without authorization by default set defaultAuth: null
   *** The individual route configs which has auth option won't be overridden.
   */
  defaultAuth: ['admin', 'agency-user', 'onlyGuest'],
  agencyAdmin: ['agency-admin'],
  providerAdmin: ['provider-admin'],
  /*
    Default redirect url for the logged-in user,
   */
  loginRedirectUrl: '/',
};

export default settingsConfig;
