import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import LogForm from '../../apps/logs/tabs/WDM/orders/LogForm'
import LogsDetailsPage from './LogsDetailsPage';
import { authRoles } from 'src/app/auth';

// const Order = lazy(() => import('./order/Order'));
const LogsApp = lazy(() => import('./LogsApp'));
const LogsMain = lazy(() => import('./LogsMain'));
const WDM = lazy(() => import('./tabs/WDM/orders/Orders'));
const TDM = lazy(() => import('./tabs/TDM/orders/Orders'));
const VISUALAPP = lazy(() => import('./tabs/VISUALAPP/orders/Orders'));
// const LogsDetailsPage = lazy(() => import('./LogsDetailsPage'));


const logsConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.commonuser,
  routes: [
    {
      path: 'apps/logs',
      element: <LogsMain />,
    },
    
    {
        path: 'apps/logs/wdms',
        element: <WDM  />,
        children: [
            {
                path: ':id',
                element: <LogForm />,
            },
          ],
    },
    {
      path: 'apps/logs/wdms/logsDetails/:id',
      element: <LogsDetailsPage />
    },
    {
        path: 'apps/logs/tdm',
        element: <TDM />,
        children: [
          {
              path: ':id',
              element: <LogForm />,
          }
        ],
    },
    {
      path: 'apps/logs/tdm/logsDetails/:id',
      element: <LogsDetailsPage />
    },
    
    {
        path: 'apps/logs/visualApp',
        element: <VISUALAPP />,
        children: [
          {
              path: ':id',
              element: <LogForm />,
          }
        ],
    }, 
    {
      path: 'apps/logs/vap/logsDetails/:id',
      element: <LogsDetailsPage />
    },
  ],
};

export default logsConfig;
