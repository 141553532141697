import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import Button from "@mui/material/Button";
import { forwardRef, useState } from "react";
import clsx from "clsx";
import PhoneNumberInput from "./PhoneNumberInput";
import AgencyModel from "../../model/AgencyModel";
import ContactNumModel from "../ContactsModel/ContactNumModel";

const PhoneNumberSelector = forwardRef(
  ({ value, onChange, className }, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const addContect = (phone, label, designation) => {
      const newContact = {
        phone,
        name: label,
        designation,
      };
      if (
        (value[value?.length - 1]?.name == "" &&
          value[value?.length - 1]?.phone == "",
        value[value?.length - 1]?.designation == "")
      ) {
        onChange([newContact]);
      } else {
        onChange([...value, newContact]);
      }
    };
    return (
      <div className={clsx("w-full", className)} ref={ref}>
        {value?.map((item, index) => (
          <PhoneNumberInput
            value={item}
            key={index}
            onChange={(val) => {
              onChange(
                value.map((_item, _index) => (index === _index ? val : _item))
              );
            }}
            onRemove={() => {
              onChange(value.filter((_item, _index) => index !== _index));
            }}
            hideRemove={value.length === 1}
          />
        ))}
        <div className="flex justify-between">
          <Button
            className="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer"
            onClick={() => onChange([...value, AgencyModel().contacts[0]])}
          >
            <FuseSvgIcon size={20}>heroicons-solid:plus-circle</FuseSvgIcon>

            <span className="ml-8 font-medium text-secondary group-hover:underline">
              Add a phone number
            </span>
          </Button>
          <Button
            className="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer"
            onClick={() => setIsModalOpen(true)}
          >
            <FuseSvgIcon size={20}>heroicons-solid:plus-circle</FuseSvgIcon>

            <span className="ml-8 font-medium text-secondary group-hover:underline">
              Add contacts with CRM
            </span>
          </Button>
          <ContactNumModel
            addContect={addContect}
            values={value}
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        </div>
      </div>
    );
  }
);

export default PhoneNumberSelector;
