import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import IconButton from '@mui/material/IconButton';
import { useEffect } from 'react';

const phoneRegExp = "^(?:00|\\+)[0-9\\s.\\/-]{6,20}$";
const schema = yup.object().shape({
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  name: yup.string(),
  designation: yup.string(),
});

var defaultValues = {
  phone: '',
  name: '',
  designation: '',
};

function PhoneNumberInput(props) {
  const { value, hideRemove } = props;

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    reset(value);
  }, [reset, value]);

  function onSubmit(data) {
    props.onChange(data);
  }


  return (
    <form className="mb-32" onChange={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="phone"
        render={({ field }) => (
          <TextField
            {...field}
            label="Contact"
            placeholder="Contact"
            variant="outlined"
            fullWidth
            error={!!errors.phone}
            helperText={errors?.phone?.message}

          />
        )}
      />
      <div className='relative flex gap-48 mt-28 w-full'>
        <div className='w-full'>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                {...field}
                className=""
                label="name"
                placeholder="Name"
                variant="outlined"
                fullWidth
                error={!!errors.name}
                helperText={errors?.name?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FuseSvgIcon size={20}>heroicons-solid:tag</FuseSvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className='w-full'>
          <Controller
            control={control}
            name="designation"
            render={({ field }) => (
              <TextField
                {...field}
                className=""
                label="designation"
                placeholder="Designation"
                variant="outlined"
                fullWidth
                error={!!errors.designation}
                helperText={errors?.designation?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FuseSvgIcon size={20}>heroicons-solid:tag</FuseSvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        {!hideRemove && (
          <div className='flex justify-center items-center'>
          <IconButton
            style={{
              width: "32px",
              height: "32px"
            }}
            className='bg-red-700'
            onClick={(ev) => {
              ev.stopPropagation();
              props.onRemove();
            }}
          >
            <FuseSvgIcon size={20}>heroicons-solid:trash</FuseSvgIcon>
          </IconButton>
          </div>
        )}
      </div>

    </form>
  );
}

PhoneNumberInput.defaultProps = {
  hideRemove: false,
};

export default PhoneNumberInput;
