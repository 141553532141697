import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const ApkMngrApp = lazy(() => import('./ApkMngrApp'));

const ApkMngrAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/displays/apk-manager/:playerId',
      element: <ApkMngrApp />,
    },
  ],
};

export default ApkMngrAppConfig;
