import { lazy } from 'react';
import AgencyView from './agency/AgencyView';
import AgencyForm from './agency/AgencyForm';
import { authRoles } from 'src/app/auth';

const AgenciesApp = lazy(() => import('./AgenciesApp'));

const AgenciesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.adminProvider,
  routes: [
    {
      path: 'apps/agencies',
      element: <AgenciesApp />,
      children: [
        {
          path: ':id',
          element: <AgencyView />,
        },
        {
          path: ':id/edit',
          element: <AgencyForm />,
        },
      ],
    },
  ],
};

export default AgenciesAppConfig;
