import { lazy } from 'react';
import ProvidersView from './providers/ProvidersView';
import ProvidersForm from './providers/ProvidersForm';
import { authRoles } from 'src/app/auth';

const ProvidersApp = lazy(() => import('./ProvidersApp'));

const ProvidersAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'apps/providers',
      element: <ProvidersApp />,
      children: [
        {
          path: ':id',
          element: <ProvidersView />,
        },
        {
          path: ':id/edit',
          element: <ProvidersForm />,
        },
      ],
    },
  ],
};

export default ProvidersAppConfig;
