import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography } from '@mui/material';
import { selectUser } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import { selectProfileData } from '../store/profileSlice';
import PhoneNumberSelector from '../phone-number-selector/PhoneNumberSelector';

function BasicInfoTab(props) {
  const { control, formState: { errors } } = useFormContext();
  const userData = useSelector(selectUser);
  const agencyData = useSelector(selectProfileData)
  const parsedPermissions = JSON.parse(agencyData?.permission ?? '[]');
  return (
    <div className=''>
      <div className='w-1/2'>
        <Typography className='text-20 font-600'>General Information</Typography>

        {/* First Name Field */}
        <Typography className="font-medium text-md mt-24">First Name <span style={{ color: "red" }}>*</span></Typography>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-8"
              error={!!errors.firstName}
              helperText={errors?.firstName?.message}
              id="firstName"
              variant="outlined"
              fullWidth
            />
          )}
        />

        {/* Last Name Field */}
        <Typography className="font-medium text-md mt-24">Last Name <span style={{ color: "red" }}>*</span></Typography>
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mt-8"
              error={!!errors.lastName}
              helperText={errors?.lastName?.message}
              id="lastName"
              variant="outlined"
              fullWidth
            />
          )}
        />

      </div>
      {userData.role === 'agency-admin' &&
        <>
          <div className='w-1/2 pt-40'>
            {userData.role === 'agency-admin' && parsedPermissions.includes('gtfs') && (
              <>
                <Typography className='text-20 font-600 mt-40'>Gtfs Realtime URLS</Typography>
                {/* GTFs Realtime Alert URL */}
                <Typography className="font-medium text-md mt-24">Alert URL</Typography>
                <Controller
                  name="alertUrl"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8"
                      error={!!errors.alertUrl}
                      helperText={errors.alertUrl?.message}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />

                {/* GTFs Realtime Vehicle Position URL */}
                <Typography className="font-medium text-md mt-24">Vehicle Position URL</Typography>
                <Controller
                  name="vehiclePositionUrl"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8"
                      error={!!errors.vehiclePositionUrl}
                      helperText={errors.vehiclePositionUrl?.message}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />

                {/* GTFs Realtime Trip Update URL */}
                <Typography className="font-medium text-md mt-24">Trip Update URL</Typography>
                <Controller
                  name="tripUpdateUrl"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8"
                      error={!!errors.tripUpdateUrl}
                      helperText={errors.tripUpdateUrl?.message}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />

                <Typography className='text-20 font-600 mt-40'>GTFs Data Feed Download URL</Typography>
                {/* GTFs Data Feed Download URL */}
                <Typography className="font-medium text-md mt-24">Data Feed URL</Typography>
                <Controller
                  name="dataFeedUrl"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mt-8"
                      error={!!errors.dataFeedUrl}
                      helperText={errors.dataFeedUrl?.message}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </>
            )}
          </div>

          <div className='w-1/2'>
            <Typography className='text-20 font-600 mt-40'>Contact Info</Typography>
            <Controller
              control={control}
              name="contacts"
              render={({ field }) => {
                return (
                  <PhoneNumberSelector className="mt-32" {...field} value={props.contacts} deletedData={props.deletedData} setDeletedData={props.setDeletedData} />
                )
              }}
            />
          </div>
        </>
      }
    </div>


  );
}

export default BasicInfoTab;
