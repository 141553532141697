import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const FileManagerApp = lazy(() => import('./FileManagerApp'));
const FileManagerUploadApp = lazy(() => import('./upload/FileManagerUpload'));

const FileManagerAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'apps/file-manager',
      element: <FileManagerApp />,
    },
    {
      path: 'apps/file-manager/:folderId',
      element: <FileManagerApp />,
    },
    {
      path: 'apps/file-manager/upload',
      element: <FileManagerUploadApp />,
    }
  ],
};

export default FileManagerAppConfig;
