import _ from '@lodash';

const ContactModel = (data) =>
  _.defaults(data || {}, {
    name : null,
    username: null,
    firstname: null,
    lastname: null,
    email: null,
    password: null,
    role: 2,
    isDeleted: 0 
  });

export default ContactModel;
