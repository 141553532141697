import Button from '@mui/material/Button';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Box from '@mui/system/Box';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import DateTimePicker from '@mui/lab/DateTimePicker';
import {
  addContact,
  getContact,
  newContact,
  removeContact,
  selectContact,
  updateContact,
} from '../store/apkKeySlice';
import ContactEmailSelector from './email-selector/ContactEmailSelector';
import PhoneNumberSelector from './phone-number-selector/PhoneNumberSelector';
import FirebaseService from 'src/app/services/firebaseService';
import { selectApkFiles } from '../store/apkFilesSlice';
import { getApkKeys } from '../store/apkKeysSlice';
import { getApksFiles } from '../store/apkFilesSlice';
import { useState } from 'react';
import SharedDiag from 'app/shared-components/SharedDiag';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  packageManager: yup.string().lowercase().required('You must enter a Package Manager key')
});

/**
 * Default values for provider and will be update once the API will be updated
 */
const providersOptions = [
  {
    value: '1',
    label: 'Avail',
  },
  {
    value: '2',
    label: 'TripSpark',
  },
  {
    value: '3',
    label: 'MPM',
  },
];

const agencyOptions = [
  {
    value: '1',
    label: 'Amtran',
  },
  {
    value: '2',
    label: 'BJCTA',
  }
]


const ApkKeysForm = (props) => {
  const contact = useSelector(selectContact);
  var dat = useSelector(selectApkFiles);
  const f = Object.values(dat.entities);
  const routeParams = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { control, watch, reset, handleSubmit, formState, setValue, trigger } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const form = watch();
  const [DialogStatus, setDialogStatus] = useState(false);

  useEffect(() => {
    reset({ ...contact });
  }, [contact, reset]);

  useEffect(() => {
    if (routeParams.id === 'new') {
      reset({
        provider: null,
        agency: null
      },
        {
          keepDefaultValues: true,
        });
      dispatch(newContact());

    } else {

      dispatch(getContact(routeParams.id)).then(() => {
        setValue('packageManager', routeParams.id);
      });
    }
  }, [dispatch, routeParams, setValue, trigger, reset]);




  /**
   * Form Submit
   */

  function onSubmit(data) {
    if (routeParams.id === 'new') {
      dispatch(addContact(data)).then(() => {
        navigate(`/apps/apk-keys-managment`);
        dispatch(getApkKeys());
        dispatch(getApksFiles());
      });
    } else {
      data['id'] = routeParams.id;
      dispatch(updateContact(data)).then(() => {
        navigate(`/apps/apk-keys-managment`);
        dispatch(getApkKeys());
        dispatch(getApksFiles());
      });
    }
  }

  function handleRemoveContact(StatusAgree) {
    if (StatusAgree) {
      dispatch(removeContact(routeParams.id)).then(() => {
        navigate('/apps/apk-keys-managment');
        dispatch(getApkKeys());
      });
    } else {
      navigate('/apps/apk-keys-managment');
      dispatch(getApkKeys());

    }
  }

  if (_.isEmpty(form) || !contact) {
    return <FuseLoading />;
  }

  return (
    <>
      {(DialogStatus) &&
        <SharedDiag status={DialogStatus} OnhandleRemove={handleRemoveContact} message={"Confirm delete the Apk Keys."} />
      }
      <Box
        className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
        sx={{
          backgroundColor: 'background.default',
        }}
      >
      </Box>

      <div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
        <Controller
          name="provider"
          control={control}
          defaultValue={[]}
          render={({ field: { onChange, value, onBlur, ref } }) => (
            <Autocomplete
              className="mt-32"
              freeSolo
              options={providersOptions}
              value={value}
              fullWidth
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select provider"
                  label="Provider"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.Autocomplete}
                  helperText={errors?.Autocomplete?.message}
                  onBlur={onBlur}
                  inputRef={ref}
                />
              )}
            />
          )}
        />
        <Controller
          name="agency"
          control={control}
          defaultValue={[]}
          render={({ field: { onChange, value, onBlur, ref } }) => (
            <Autocomplete
              className="mt-32"
              freeSolo
              options={agencyOptions}
              value={value}
              fullWidth
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select agency"
                  label="Agency"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.Autocomplete}
                  helperText={errors?.Autocomplete?.message}
                  onBlur={onBlur}
                  inputRef={ref}
                />
              )}
            />
          )}
        />
        <Controller
          control={control}
          name="packageManager"
          render={({ field }) => (
            <TextField
              className="mt-32"
              {...field}
              label="MPM Package Manager Key"
              placeholder="MPM Package Manager Key for Android APK"
              id="packageManager"
              error={!!errors.packageManager}
              helperText={errors?.packageManager?.message}
              variant="outlined"
              required
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FuseSvgIcon size={20}>heroicons-solid:user-circle</FuseSvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="apks"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              multiple
              id="apks"
              className="mt-32"
              options={f}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(_props, option, { selected }) => (
                <li {..._props}>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  {option.name}
                </li>
              )}
              value={(value) ? value.map((id) => _.find(f, { id })) : []}
              onChange={(event, newValue) => {
                onChange(newValue.map((item) => item.id));
              }}
              fullWidth
              renderInput={(params) => <TextField {...params} label="APKs" placeholder="APKs" />}
            />
          )}
        />
      </div>
      <Box
        className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t"
        sx={{ backgroundColor: 'background.default' }}
      >
        {routeParams.id !== 'new' && (
          <Button color="error" onClick={() => { setDialogStatus(true) }}>
            Delete
          </Button>
        )}
        <Button className="ml-auto" component={NavLinkAdapter} to={-1}>
          Cancel
        </Button>
        {(routeParams.id != 'new') ?
          <Button
            className="ml-8"
            variant="contained"
            color="secondary"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
          :
          <Button
            className="ml-8"
            variant="contained"
            color="secondary"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        }
      </Box>
    </>
  );
};

export default ApkKeysForm;
