import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton } from "@mui/material";
import ReactJson from "react-json-view";
import { saveAs } from 'file-saver';
import DownloadIcon from '@mui/icons-material/Download';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

const JsonTable = ({ myJson, open, setOpen, fileName }) => {
    const handleClose = () => {
        setOpen(false);
    };

    const downloadJsonFile = () => {
        const blob = new Blob([JSON.stringify(myJson, null, 2)], { type: 'application/json' });
        saveAs(blob, `${fileName}.json`);
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth maxWidth="lg" style={{ maxHeight: "90vh !important", maxWidth: "60vw !important", overflow: "hidden", padding: 0 }}>
            <DialogContent className='p-20'>
                <div className="flex justify-between w-full items-center">
                    <DialogTitle id="alert-dialog-title">{`${fileName}`}</DialogTitle>
                    <IconButton
                        size="large"
                        onClick={() => handleClose()}
                    >
                        <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
                    </IconButton>
                </div>
                <DialogContent>
                    <ReactJson
                        name="json"
                        enableClipboard={true}
                        displayDataTypes={false}
                        collapsed={false}
                        theme="monokai"
                        src={myJson}
                        style={{ padding: "1rem", maxHeight: "50vh", overflow: "auto" }}
                    />
                </DialogContent>
                <DialogActions className='gap-10'>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={downloadJsonFile} color="primary" variant="contained" startIcon={<DownloadIcon />}>
                        Download
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default JsonTable;
