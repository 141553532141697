import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import { addContact, removeContact, updateContact } from './providerSlice';


export const getContacts = createAsyncThunk(
  'providersApp/providers/getContacts',
  async (params, { getState }) => {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Provider/getall`);
    const data = await response.data;
    return data.Providers.data;
  }
);

const providersAdapter = createEntityAdapter({
    selectId: (state) => state.provider_id
});

export const selectSearchText = ({ providersApp }) => providersApp.providers.searchText;


export const { selectAll: selectContacts, selectById: selectContactsById } =
  providersAdapter.getSelectors((state) => state.providersApp.providers);

export const selectFilteredContacts = createSelector(

  [selectContacts, selectSearchText],
  (contacts, searchText) => {
    if (searchText.length === 0) {
      return contacts;
    }
    return FuseUtils.filterByProviders(contacts, searchText);
  }

);

export const selectGroupedFilteredContacts = createSelector(
  [selectFilteredContacts],
  (contacts) => {
    return contacts
      .sort((a, b) => a?.name.localeCompare(b?.name, 'es', { sensitivity: 'base' }))
      .reduce((r, e) => {
          const group = e?.name[0];
          if (!r[group]) r[group] = { group, children: [e] };
          else r[group].children.push(e);

          return r;
      }, {});
  }
);

const providersSlice = createSlice({
  name: 'providersApp/providers',
  initialState: providersAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setContactsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;

      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [updateContact.fulfilled]: providersAdapter.upsertOne,
    [addContact.fulfilled]: providersAdapter.addOne,
    [removeContact.fulfilled]: (state, action) => providersAdapter.removeOne(state, action.payload),
    [getContacts.fulfilled]: (state, action) => {
      const data = action.payload;
      providersAdapter.setAll(state, data);
      state.searchText = '';
    },
  },
});

export const { setContactsSearchText } = providersSlice.actions;

export default providersSlice.reducer;
