import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";

  export const getAllDaasUser = createAsyncThunk(
    "userProfileApp/daasconfig/getAllDaasUser",
    async (id) => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/daas/getAll`
      );
      const data = await response?.data;
      return data
    }
  );

export const createDaasAuthUser = createAsyncThunk(
  "userProfileApp/daasconfig/createDaasAuthUser",
  async (requestObj, { dispatch, getState }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/auth/daas/create`,
        requestObj
      );
      const data = response?.data;
      return data;
    } catch (err) {
      return err;
    }
  }
); 

export const blockDaasAuthUser = createAsyncThunk(
    "userProfileApp/daasconfig/blockDaasAuthUser",
    async (requestObj, { dispatch, getState }) => {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/blockUser`,
          requestObj
        );
        const data = response?.data;
        return data;
      } catch (err) {
        return err;
      }
    }
  );  

  export const unBlockDaasAuthUser = createAsyncThunk(
    "userProfileApp/daasconfig/unBlockDaasAuthUser",
    async (requestObj, { dispatch, getState }) => {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/unBlockUser`,
          requestObj
        );
        const data = response?.data;
        return data;
      } catch (err) {
        return err;
      }
    }
  );

  export const removeDaasAuthUser = createAsyncThunk(
    "userProfileApp/daasconfig/removeDaasAuthUser",
    async (requestObj, { dispatch, getState }) => {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/removeUser`,
          {data: requestObj}
        );
        const data = response?.data;
        return data;
      } catch (err) {
        return err;
      }
    }
  );

  export const updateDaasAuthUser = createAsyncThunk(
    "userProfileApp/daasconfig/updateDaasAuthUser",
    async (requestObj, { dispatch, getState }) => {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/updateUser`,
          requestObj
        );
        const data = response?.data;
        return data;
      } catch (err) {
        return err;
      }
    }
  ); 
  export const createPassCode = createAsyncThunk(
    "userProfileApp/daasconfig/createPassCode",
    async (requestObj, { dispatch, getState }) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/daas-config/createpasscode`,
          requestObj
        );
        const data = response?.data;
        return data;
      } catch (err) {
        return err;
      }
    }
  );
//   export const getAllApksByAgency = createAsyncThunk(
//     "playerGroupApp/AssignApkApp/getApkManagers",
//     async (val, { dispatch, getState }) => {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_BASE_URL}/api/apk/getAllApksByAgency/${
//           getState()?.user?.agency_id
//         }`
//       );
//       return response?.data;
//     }

//   );

//   export const assignapktoplayergroup = createAsyncThunk(
//     "playerGroupApp/AssignApkApp/assignapktoplayergroup",
//     async (assignedApkObj, { dispatch, getState }) => {
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/apkmgr/assignapktoplayergroup`, assignedApkObj);
//       const data = response?.data;
//       return data;
//     }
//   );

//   export const updateapktoplayergroup = createAsyncThunk(
//     "playerGroupApp/AssignApkApp/assignapktoplayergroup",
//     async (assignedApkObj, { dispatch, getState }) => {
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/apkmgr/updateApktoplayergroup`, assignedApkObj);
//       const data = response?.data;
//       return data;
//     }
//   );

//   export const uninstallApktoplayergroup = createAsyncThunk(
//     "playerGroupApp/AssignApkApp/uninstallApktoplayergroup",
//     async (assignedApkObj, { dispatch, getState }) => {
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/apkmgr/uninstallApktoplayergroup`, assignedApkObj);
//       const data = response?.data;
//       return data;
//     }
//   );

//   export const deleteapktoplayergroup = createAsyncThunk(
//     "playerGroupApp/AssignApkApp/assignapktoplayergroup",
//     async (deleteApkObj, { dispatch, getState }) => {
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/apkmgr/deleteApktoplayergroup`, deleteApkObj);
//       const data = response?.data;
//       return data;
//     }
//   );

//   export const getAssignedApkToPlayerGroup = createAsyncThunk(
//     "playerGroupApp/AssignApkApp/getAssignedApkToPlayerGroup",
//     async (requestObj, { dispatch, getState }) => {
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/apkmgr/getAssignedApkToPlayerGroup`, requestObj);
//       const data = response?.data;
//       return data;
//     }
//   );

//   export const getAllApksStatusInPlayerGroup = createAsyncThunk(
//     "playerGroupApp/AssignApkApp/getAllApksStatusInPlayerGroup",
//     async (requestObj, { dispatch, getState }) => {
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/apkmgr/getAllApksStatusInPlayerGroup`, requestObj);
//       const data = response?.data;
//       return data;
//     }
//   );

const daasConfigAdapter = createEntityAdapter({
    selectId: (state) => state.daas_user_id
  });

export const { selectAll: selectDaasConfig, selectById: selectDaasConfigById } =
  daasConfigAdapter.getSelectors((state) => state.userProfileApp.DaasConfigSlice);

const DaasConfigSlice = createSlice({
  name: "userProfileApp/daasconfig",
  initialState: daasConfigAdapter.getInitialState({
    //   searchText: "",
    //   allApks: {},
    //   finalAssignedObject: [],
    //   selectApk: {},
  }),
  reducers: {
    //   setPlayersSearchText: {
    //     reducer: (state, action) => {
    //       state.searchText = action.payload;
    //     },
    //     prepare: (event) => ({ payload: event.target.value || "" }),
    //   },
    //   setSelectApk: (state, action) => {
    //     state.selectApk = action.payload;
    //   },
    //   setAllAssignedData: (state, action) => {
    //     state.finalAssignedObject.push(action.payload);
    //   },
    //   setDeleteAssignedData: (state, action) => {
    //     state.finalAssignedObject = action.payload;
    //   }
  },
  extraReducers: {
      [getAllDaasUser.fulfilled]: daasConfigAdapter.setAll,
    //   [removePlayers.fulfilled]: (state, action) =>
    //     playersAdapter.removeMany(state, action.payload),
    //   [getAllApksByAgency.fulfilled]: (state, action) => {
    //     if (action?.payload?.length > 0) {
    //       const groupedData = action?.payload.reduce((result, item) => {
    //         const filepathParts = item?.zoho_filepath.split("/");
    //         const key = filepathParts?.slice(0, filepathParts?.length-1).join("/"); // Combine the first two parts as the key
    //         if (!result[key]) {
    //           result[key] = [];
    //         }
    //         result[key]?.push(item);
    //         return result;
    //       }, {});
    //       state.allApks = groupedData;
    //     } else {
    //       state.allApks = {};
    //     }
    //   },
  },
});

//   export const {  } = playersSlice.actions;

//   export const selectPlayersSearchText = ({ playerGroupApp }) =>
//     playerGroupApp.players.searchText;

//   export const selectAllApks = ({ playerGroupApp }) =>
//     playerGroupApp.players.allApks;

//   export const selectedApk = ({ playerGroupApp }) =>
//     playerGroupApp.players.selectApk;

//   export const selectedAllAssignedApk = ({ playerGroupApp }) =>
//     playerGroupApp.players.finalAssignedObject;

export default DaasConfigSlice.reducer;
