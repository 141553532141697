import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const ApkFileManagerApp = lazy(() => import('./ApkFileManagerApp'));
const ApkFileManagerUploadApp = lazy(() => import('./upload/ApkFileManagerUpload'));

const ApkFileManagerAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.allUser,
  routes: [
    {
      path: 'apps/apk-manager',
      element: <ApkFileManagerApp />,
    },
    {
      path: 'apps/apk-manager/*',
      element: <ApkFileManagerApp />,
    },
    {
      path: 'apps/apk-manager/upload',
      element: <ApkFileManagerUploadApp />,
    }
  ],
};

export default ApkFileManagerAppConfig;
