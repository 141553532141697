import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import { addContact, removeContact, updateContact } from './apkKeySlice';
import firebaseService from 'src/app/services/firebaseService';

export const getApkKeys = createAsyncThunk(
  'apkKeysApp/apkKeys/getApkKeys',
  async dispatch => {
    firebaseService.init(success => {
      if (!success) {
        resolve();
      }
    });

    return firebaseService
      .getApkKeys()
      .then((val) => {
        var data = {
          items: ((val === null) ? [] : val),
        }
        return data;
      })
      .catch(error => {
        console.log('Error--->', error)
        dispatch(false)
      });
  }
);

const apkKeysAdapter = createEntityAdapter({
  selectId: (state) => Object.keys(state)
});

export const selectSearchText = ({ apkKeysApp }) => apkKeysApp.apkKeys.searchText;

export const { selectAll: selectContacts, selectById: selectContactsById } =
  apkKeysAdapter.getSelectors((state) => state.apkKeysApp.apkKeys);

export const selectFilteredContacts = createSelector(
  [selectContacts, selectSearchText],
  (contacts, searchText) => {
    if (searchText.length === 0) {
      return contacts;
    }
    return FuseUtils.filterArrayByString(contacts, searchText);
  }
);

export const selectGroupedFilteredContacts = createSelector(
  [selectFilteredContacts],
  (contacts) => {
    return contacts
      .sort()
      .reduce((r, e) => {
        Object.keys(e).forEach((keyname) => {
          const group = keyname[0];
          const k = {}
          k[keyname] = e[keyname];
          if (!r[group]) r[group] = { group, children: [k] };
          else r[group].children.push(k);
        })
        return r;
      }, {});
  }
);

const apkKeysSlice = createSlice({
  name: 'apkKeysApp/apkKeys',
  initialState: apkKeysAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setContactsSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [updateContact.fulfilled]: apkKeysAdapter.upsertOne,
    [addContact.fulfilled]: apkKeysAdapter.addOne,
    [removeContact.fulfilled]: (state, action) => apkKeysAdapter.removeOne(state, action.payload),
    [getApkKeys.fulfilled]: (state, action) => {
      const data = action.payload;
      apkKeysAdapter.setAll(state, data);
      state.searchText = '';
    },
  },
});

export const { setContactsSearchText } = apkKeysSlice.actions;

export default apkKeysSlice.reducer;
