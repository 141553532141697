import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const Stop = lazy(() => import('./stop/Stop'));
const Stops = lazy(() => import('./stops/Stops'));

const ECommerceAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/stops',
      element: <Stops />,
    },
    {
      path: 'apps/stops/:stopId/*',
      element: <Stop />,
    },
  ],
};

export default ECommerceAppConfig;
