import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import _ from '@lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { showMessage } from 'app/store/fuse/messageSlice';
import { updateProfileData } from '../store/profileSlice';

function ProfileHeader({ agency_id, deletedData }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const methods = useFormContext();
    const { formState, watch, getValues } = methods;
    const { isValid, dirtyFields } = formState;

    function handleupdateProfile() {
        const object = getValues()
        let contactsBody = {
            contactsToUpdate: [],
            contactsToDelete: [...deletedData],
            contactsToAdd: []
        };

        object?.contacts?.forEach(contact => {
            if ('contact_id' in contact && 'agency_id' && 'is_deleted' in contact) {
                contactsBody.contactsToDelete.push(contact);
            }
            else if ('contact_id' in contact && 'agency_id' in contact) {
                contactsBody.contactsToUpdate.push(contact);
            } else {
                contactsBody.contactsToAdd.push(contact);
            }
        });
        const payloadBody = {
            agencyInformationBody: {
                alert: object?.alertUrl,
                vehiclePosition: object?.vehiclePositionUrl,
                tripUpdate: object?.tripUpdateUrl,
                datafeed: object?.dataFeedUrl,
            },
            agencyBody: {
                firstname: object?.firstName,
                lastname: object?.lastName,
            },
            contactsBody
        };

        dispatch(updateProfileData({ agency_id, payloadBody }))
            .then(res => {
                const { status, Error } = res?.payload || {};
                if (status !== 200) {
                    const errorMessage = Error || 'Profile Data Not Updated Successfully.';
                    dispatch(showMessage({ message: errorMessage, variant: "error" }));
                } else {
                    dispatch(showMessage({ message: "Profile Data Updated successfully.", variant: "success" }));
                    navigate('/apps/userProfile');
                }
            })
            .catch(error => {
                dispatch(showMessage({ message: 'Profile Data Updated successfully.', variant: "error" }));
            });

    }

    return (
        <div className="flex flex-col sm:flex-row flex-1 w-full items-center justify-between space-y-8 sm:space-y-0 py-32 px-24 md:px-32">
            <div className="flex flex-col items-center sm:items-start space-y-8 sm:space-y-0 w-full sm:max-w-full min-w-0">
                <motion.div
                    initial={{ x: 20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1, transition: { delay: 0.3 } }}
                >
                    <Typography
                        className="flex items-center sm:mb-12"
                        component={Link}
                        role="button"
                        to="/apps/userProfile"
                        color="inherit"
                    >
                        <FuseSvgIcon size={20}>
                            {theme.direction === 'ltr'
                                ? 'heroicons-outline:arrow-sm-left'
                                : 'heroicons-outline:arrow-sm-right'}
                        </FuseSvgIcon>
                        <span className="flex mx-4 font-medium">Agency</span>
                    </Typography>
                </motion.div>

                <div className="flex items-center max-w-full">
                    <motion.div
                        className="flex flex-col items-center sm:items-start min-w-0 mx-8 sm:mx-16"
                        initial={{ x: -20 }}
                        animate={{ x: 0, transition: { delay: 0.3 } }}
                    >
                        <Typography className="text-16 sm:text-20 truncate font-semibold">
                            Update Agency
                        </Typography>
                    </motion.div>
                </div>
            </div>
            <motion.div
                className="flex"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0, transition: { delay: 0.3 } }}
            >
                <Button
                    className="whitespace-nowrap mx-4"
                    variant="contained"
                    color="secondary"
                    onClick={handleupdateProfile}
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                >
                    Update
                </Button>
            </motion.div>
        </div>
    );
}

export default ProfileHeader;
