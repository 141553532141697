import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const ApkFileManagerApp = lazy(() => import('./ApkFileManagerApp'));
const ApkFileManagerUploadApp = lazy(() => import('./upload/ApkFileManagerUpload'));

const JsonApkFileManagerAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.allUser,
  routes: [
    {
      path: 'apps/Jsonapk-manager',
      element: <ApkFileManagerApp />,
    },
    // {
    //   path: 'apps/Jsonapk-manager/*',
    //   element: <ApkFileManagerApp />,
    // },
    // {
    //   path: 'apps/Jsonapk-manager/upload',
    //   element: <ApkFileManagerUploadApp />,
    // }
  ],
};

export default JsonApkFileManagerAppConfig;
