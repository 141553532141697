import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/fuse/navigationSlice';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import { selectUser } from 'app/store/userSlice';

function Navigation(props) {
  const user = useSelector(selectUser);
  const navigation = useSelector(selectNavigation);

  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const dispatch = useDispatch();


  return useMemo(() => {
    var arrays = [];
    function handleItemClick(item) {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }
    if(user?.permission){
    navigation?.map((v) => {
      if(v.id == 'master-angency'){arrays.push(v)}
      JSON.parse(user?.permission)?.forEach((modulesName) => {
        if(v.id == modulesName){
          arrays.push(v);
        }
      }) 
    })
  }
    return (
      <FuseNavigation
        className={clsx('navigation', props.className)}
        navigation={(user?.permission) ? arrays: navigation}
        layout={props.layout}
        dense={props.dense}
        active={props.active}
        onItemClick={handleItemClick}
      />
    );
  }, [dispatch,isMobile, navigation, props.active, props.className, props.dense, props.layout]);
}

Navigation.defaultProps = {
  layout: 'vertical',
};

export default memo(Navigation);
