/* eslint import/no-extraneous-dependencies: off*/
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

import config from './firebaseServiceConfig';


class FirebaseService {
	init(success) {
		if (Object.entries(config).length === 0 && config.constructor === Object) {
			if (process.env.NODE_ENV === 'development') {
				console.warn(
					'Missing Firebase Configuration at src/app/services/firebaseService/firebaseServiceConfig.js'
				);
			}
			success(false);
			return;
		}

		if (firebase.apps.length) {
			return;
		}
		firebase.initializeApp(config);
		this.db = firebase.database().ref();
		this.auth = firebase.auth();
		
		this.storage = firebase.storage();
		success(true);
	}

	getApkFiles = callback => {
		if (!firebase.apps.length) {
			return false;
		}
		return new Promise((resolve, reject) => {
			firebase.database().ref('Apk')
				.once('value')
				.then(snapshot => {
					const data = snapshot.val();
					resolve(data);
				});
		});
	};


	// get apkskeydata 
	getApkKeys = callback => {
		if (!firebase.apps.length) {
			return false;
		}
		return new Promise((resolve, reject) => {
			firebase.database().ref('Agencysss')
				.once('value')
				.then(snapshot => {
					const dataapkKeys = snapshot.val();
					resolve(dataapkKeys);
				});
		});

	}

	// store data of package manager keys
	StoreData = (data) => {
		if (!firebase.apps.length) {
			return false;
		}
		return new Promise((resolve, reject) => {
			const key = data.packageManager;
			delete data.packageManager;
			firebase.database().ref().child(`Agencysss/${key}`).set(data, (err) => {
				if (err) {
					console.log('Apk key Err', err);
				} else {
					var k = {};
					k[key] = data;
					resolve(k);
				}
			})
		});
	}

	// getApkKeysByID
	getApkKeysByID = (id) => {
		if (!firebase.apps.length) {
			return false;
		}
		return new Promise((resolve, reject) => {
			firebase.database().ref(`Agencysss/${id}`)
				.once('value')
				.then(snapshot => {
					const apkKeys = snapshot.val();
					resolve(apkKeys);
				});
		});
	}

	// delete ApkKeys
	deleteApkKeys = (id) => {
		if (!firebase.apps.length) {
			return false;
		}
		return new Promise((resolve, reject) => {
			firebase.database().ref(`Agencysss/${id}`).remove((err) => {
				if (err) {
					console.log('No Deleted Apk Key...');
				} else {
					resolve('Deleted Apk Key...');
				}
			})
		});
	}

	// Update Apk Keys

	updateApkKeys = (data) => {
		if (!firebase.apps.length) {
			return false;
		}
		return new Promise((resolve, reject) => {
			const id = data.id;
			const key = data.packageManager;
			delete data.id;
			delete data.packageManager;
			firebase.database().ref(`Agencysss/${id}`).remove((err) => {
				if (!err) {
					firebase.database().ref(`Agencysss/${key}`).set(data, (err) => {
						if (err) {
							
						} else {
							var k = {};
							k[key] = data;
							resolve(k);
						}

					});

				}
			});
		});
	}

}

const instance = new FirebaseService();

export default instance;
