import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const AssignApk = lazy(() => import('./assignapk/AssignApk'));
const AssignApkApp = lazy(() => import('./assignapks/AssignApkApp'));

const AssignApkAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/assign-apk',
      element: <AssignApkApp />,
    },
    {
      path: 'apps/assign-apk/:groupingId/*',
      element: <AssignApk />,
    }
  ],
};

export default AssignApkAppConfig;
