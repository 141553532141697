import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import { addAgency, removeAgency, updateAgency } from './agencySlice';

export const getAgencies = createAsyncThunk(
  'agenciesApp/agencies/getAgencies',
  async (params, { getState }) => {
    if(getState()?.user?.role == 'provider-admin'){
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Agency/getallPro/${getState()?.user?.id}`);
      const data = await response.data;
      return data?.staff?.data;
    }else{
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Agency/admin/getall`);
    const data = await response.data;
    return data?.staff?.data;
    }
  }
);

const agenciesAdapter = createEntityAdapter({
  selectId: (state) => state.agency_id
});

export const selectSearchText = ({ agenciesApp }) => agenciesApp.agencies.searchText;

export const selectFilterValue = ({ agenciesApp }) => agenciesApp.agencies.filterValue;

export const operationStatus = ({ agenciesApp }) => agenciesApp.agencies.operationStatuses;

export const { selectAll: selectAgencies, selectById: selectAgenciesById } =
  agenciesAdapter.getSelectors((state) => state.agenciesApp.agencies);

export const selectFilterValueAgencies = createSelector(
    [selectAgencies, selectFilterValue],
    (agencies, filterVal) => {
      if (filterVal == 0) {
        let agenciesObj = agencies?.filter((val) => {
          return val?.isActive == 0 
        })
        return agenciesObj;

      }else if(filterVal == 1){
        let agenciesObj = agencies?.filter((val) => {
                return val?.isActive == 1
              })
              return agenciesObj;
      }else{
        return agencies;
      }
    }
  );

export const selectFilteredAgencies = createSelector(
  [selectFilterValueAgencies, selectSearchText],
  (agencies, searchText) => {
    if (searchText.length === 0) {
      return agencies;
    }
    return FuseUtils.filterArrayByAgency(agencies, searchText);
  }
);

export const selectGroupedFilteredAgencies = createSelector(
  [selectFilteredAgencies],
  (agencies) => {
    return agencies
      .sort((a, b) => a.users.username.localeCompare(b.users.username, 'es', { sensitivity: 'base' }))
      .reduce((r, e) => {
        const group = e.users.username[0];
        if (!r[group]) r[group] = { group, children: [e] };
        else r[group].children.push(e);

        return r;
      }, {});
  }
);

const agenciesSlice = createSlice({
  name: 'agenciesApp/agencies',
  initialState: agenciesAdapter.getInitialState({
    searchText: '',
    operationStatuses: "",
    filterValue: 2
  }),
  reducers: {
    setAgenciesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
    setAgenciesFilterValue:(state, action) => {
      state.filterValue = action.payload
    },
    setOperationStatus: (state, action) => {
      state.operationStatuses = action.payload
    }
  },
  extraReducers: {
    [updateAgency.fulfilled]: agenciesAdapter.upsertOne,
    [addAgency.fulfilled]: (state, action) => agenciesAdapter.addOne(state, action.payload?.staff?.data),
    [removeAgency.fulfilled]: (state, action) => agenciesAdapter.removeOne(state, action.payload),
    [getAgencies.fulfilled]: (state, action) => {
      const data = action.payload;
      agenciesAdapter.setAll(state, data);
      state.searchText = '';
    },
  },
});

export const { setAgenciesSearchText, setOperationStatus, setAgenciesFilterValue } = agenciesSlice.actions;

export default agenciesSlice.reducer;
