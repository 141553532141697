/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['admin'],
  provider: ['provider-admin'],
  user: ['agency-admin'],
  commonuser: ['agency-admin', 'admin'],
  adminProvider: ['provider-admin', 'admin'],
  allUser: ['provider-admin', 'admin', 'agency-admin'],
  onlyGuest: [],
};

export default authRoles;
