import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getProfileData = createAsyncThunk('userProfileApp/user/get/profileData', async ({ agency_id, id }) => {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Agency/profile/get/${agency_id}`, {
        params: {
            id: id
        }
    });
    const data = await response?.data?.data[0];
    return data;
});


export const updateProfileData = createAsyncThunk(
    'userProfileApp/user/update/profileData',
    async ({ agency_id, payloadBody }) => {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/Agency/profile/update/${agency_id}`, payloadBody);
        return response;
    }
);

export const updateProfileImage = createAsyncThunk(
    'userProfileApp/user/image/agency/update',
    async ({ id, image_url }) => {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/User/profile/image/update/${id}`, { image_url });
        return response;
    }
);

const userProfileSlice = createSlice({
    name: 'userProfileApp/user',
    initialState: {},
    reducers: {},
    extraReducers: {
        [getProfileData.pending]: (state, action) => null,
        [getProfileData.fulfilled]: (state, action) => action.payload,
        [updateProfileData.pending]: (state, action) => null,
        [updateProfileData.fulfilled]: (state, action) => action.payload,
        [updateProfileImage.pending]: (state, action) => null,
        [updateProfileImage.fulfilled]: (state, action) => state,
    },
});

export const selectProfileData = ({ userProfileApp }) => userProfileApp?.userProfileSlice


export default userProfileSlice.reducer;
