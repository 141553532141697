import { lazy } from 'react';
import ApkKeysView from './apkKeys/ApkKeysView';
import ApkKeysForm from './apkKeys/ApkKeysForm';
import { authRoles } from 'src/app/auth';

const ApkKeysApp = lazy(() => import('./ApkKeysApp'));

const ApkKeysAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'apps/apk-keys-managment',
      element: <ApkKeysApp />,
      children: [
        {
          path: ':id',
          element: <ApkKeysView />,
        },
        {
          path: ':id/edit',
          element: <ApkKeysForm />,
        },
      ],
    },
  ],
};

export default ApkKeysAppConfig;
