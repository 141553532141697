
import withReducer from 'app/store/withReducer';
import reducer from './store';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { useEffect, useMemo, useState } from 'react';
import ReactJson from 'react-json-view'
import { useParams } from 'react-router-dom';
import { getLogsById } from './store/ordersSlice';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@mui/material/Typography';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useTheme } from '@mui/material/styles';
import FuseLoading from '@fuse/core/FuseLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
}));

function LogsDetailsPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const handleBackButtonClick = () => {
    history.goBack();
  };
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState();

  useEffect(() => {
    dispatch(getLogsById(id)).then((res) => {
      setData(res?.payload?.data)
      setLoading(false);
    })
  }, [id])
  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <FuseLoading />
      </div>
    );
  }
  let path = '';
  if (data?.app_type == 'WDM') {
    path = "/apps/logs/wdms";
  }
  else if (data?.app_type == 'TDM') {
    path = "/apps/logs/tdm";
  }
  else if (data?.app_type == 'VAP') {
    path = "/apps/logs/visualApp";
  }

  return (

    <FusePageSimple
      header={<div className="flex flex-col sm:flex-row  w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32 bg-#2A2E32-900 shadow-md">
        <Typography
          className="flex items-center text-white"
          component={Link}
          role="button"
          to={path}
          color="inherit"
        >
          <FuseSvgIcon size={20}>
            {theme.direction === 'ltr'
              ? 'heroicons-outline:arrow-sm-left'
              : 'heroicons-outline:arrow-sm-right'}
          </FuseSvgIcon>
          <span className="mx-4 mr-32 font-medium">Back</span>
          <span className="ml-30 font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl text-white">
            Log Information
          </span>
        </Typography>
      </div>
      }

      content={
        <>

          <div className='flex md:justify-around justify-center items-center md:flex-nowrap flex-wrap' style={{ height: "75vh" }}>
            <div className='flex justify-center items-center h-full xl:w-1/2'>
              <div className='p-20 font-500 xl:w-2/3'>
                <div className='flex flex-row justify-between m-32 bg-#2A2E32-900 shadow-md gap-10' >
                  <span className='mr-40'>Id:</span>
                  <span> {data?._id}</span>
                </div>
                <div className='flex flex-row justify-between m-32 bg-#2A2E32-900 shadow-md gap-10' >
                  <span>Agency Key: </span>
                  <span> {data?.agency_id}</span>
                </div>
                <div className='flex flex-row justify-between m-32 bg-#2A2E32-900 shadow-md gap-10' >
                  <span>Player Id: </span>
                  <span> {data?.player_id}</span>
                </div>
                <div className='flex flex-row justify-between m-32 bg-#2A2E32-900 shadow-md gap-10' >
                  <span>App Id: </span>
                  <span> {data?.app_id}</span>
                </div>
                <div className='flex flex-row justify-between m-32 bg-#2A2E32-900 shadow-md gap-10' >
                  <span>App Type: </span>
                  <span> {data?.app_type}</span>
                </div>
                <div className='flex flex-row justify-between m-32 bg-#2A2E32-900 shadow-md gap-10' >
                  <span>App Version: </span>
                  <span> {data?.app_version}</span>
                </div>
                <div className='flex flex-row justify-between m-32 bg-#2A2E32-900 shadow-md gap-10' >
                  <span>App Name: </span>
                  <span> {data?.app_name}</span>
                </div>
                <div className='flex flex-row justify-between m-32 bg-#2A2E32-900 shadow-md gap-10' >
                  <span>Error Type: </span>
                  <span> {data?.error_type}</span>
                </div>
                <div className='flex flex-row justify-between m-32 bg-#2A2E32-900 shadow-md gap-10' >
                  <span>Message:</span>
                  <span className='ml-10px'> {data?.message}</span>
                </div>
              </div>
            </div>
            <div className='flex md:w-3/6 w-3/4 min-h-sm overflow-auto p-10' style={{ backgroundColor: 'white', maxHeight: '48rem' }}> {/* Adjust 500px as needed */}
              {data?.obj?.length > 0 &&
                <ReactJson name="Log" style={{ backgroundColor: 'white', color: 'black', height: "100%" }} src={data?.obj} />
              }
            </div>
          </div>
        </>
      }
    />
  );
}

export default withReducer('logs', reducer)(LogsDetailsPage);
