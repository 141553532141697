import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
import ContactModel from '../model/ProvidersModel';

export const getContact = createAsyncThunk(
  'providersApp/task/getContact',
  async (id, { dispatch, getState }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Provider/get/${id}`);
      const data = await response.data;
      return data.data.data;
    } catch (error) {
      history.push({ pathname: `/apps/providers` });
      return null;
    }
  }
);

export const addContact = createAsyncThunk(
  'providersApp/contacts/addContact',
  async (providerData, { dispatch, getState }) => {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/Provider/add`, providerData);
    const data = await response.data;
    return data?.data?.data[0];
  }
);

export const updateContact = createAsyncThunk(
  'providersApp/contacts/updateContact',
  async (updateProviderdata, { dispatch, getState }) => {
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/Provider/update`, updateProviderdata);
    const data = await response.data;
    return data?.data?.data;
  }
);

export const removeContact = createAsyncThunk(
  'providersApp/contacts/removeContact',
  async (id, { dispatch, getState }) => {
    const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/Provider/delete`, id);
    let datas = await response.data;
    return datas;
  }
);

export const selectContact = ({ providersApp }) => providersApp.provider;

const providerSlice = createSlice({
  name: 'providersApp/contact',
  initialState: null,
  reducers: {
    newContact: (state, action) => ContactModel(),
    resetContact: () => null,
  },
  extraReducers: {
    [getContact.pending]: (state, action) => null,
    [getContact.fulfilled]: (state, action) => action.payload,
    [updateContact.fulfilled]: (state, action) => action.payload,
    [removeContact.fulfilled]: (state, action) => null,
  },
});

export const { resetContact, newContact, updateProvider } = providerSlice.actions;

export default providerSlice.reducer;
