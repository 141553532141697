import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const AlertTriggerApp = lazy(() => import('./AlertTriggerApp'));
const AlertTriggerFormApp = lazy(() => import('./AlertTriggerForm/AlertTriggerFormApp'))

const AlertTriggerAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/alert-trigger/on-demand',
      element: <AlertTriggerApp />,
    },
    {
      path: 'apps/alert-trigger/on-demand/:id',
      element: <AlertTriggerFormApp />,
    },
  ],
};

export default AlertTriggerAppConfig;
