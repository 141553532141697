import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import _ from '@lodash';
import { FormProvider, useForm } from 'react-hook-form';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { selectProfileData } from '../store/profileSlice';
import reducer from '../store';
import BasicInfoTab from './BasicInfoTab';
import ProfileHeader from './ProfileHeader';
import { useEffect, useState } from 'react';
import FuseLoading from '@fuse/core/FuseLoading';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { selectProviderData } from '../store/ProviderSlice';
import { selectUser } from 'app/store/userSlice';

function EditProfile(props) {
    const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
    const [loading, setLoading] = useState(true);
    const [deletedData, setDeletedData] = useState([]);
    const navigate = useNavigate();
    const data = useSelector(selectProfileData);
    const userData = useSelector(selectUser);
    const userId = userData.role === 'agency-admin' ? data?.agency_id : data?.provider_id

    const commonFieldsSchema = {
        firstName: yup.string().required("You must enter a First Name."),
        lastName: yup.string().required("You must enter a Last Name."),
    };

    const providerSchema = yup.object().shape(commonFieldsSchema);

    const agencySchema = yup.object().shape({
        ...commonFieldsSchema
    });

    const schema = userData.role === 'agency-admin' ? agencySchema : providerSchema;

    const defaultValues = {
        firstName: data?.firstname || '',
        lastName: data?.lastname || '',
        ...(userData.role === 'agency-admin' && {
            alertUrl: data?.agency_information?.alert || '',
            vehiclePositionUrl: data?.agency_information?.vehiclePosition || '',
            tripUpdateUrl: data?.agency_information?.tripUpdate || '',
            dataFeedUrl: data?.agency_information?.datafeed || ''
        })
    };

    const methods = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        if (!data || Object.keys(data).length === 0) {
            navigate('/apps/userProfile');
        } else {
            const timer = setTimeout(() => setLoading(false), 1000);
            return () => clearTimeout(timer);
        }
    }, [data, navigate]);

    if (loading) {
        return <FuseLoading />;
    }

    return (
        <FormProvider {...methods}>
            <FusePageCarded
                header={<ProfileHeader agency_id={userId} deletedData={deletedData} />}
                content={
                    <div className="p-16 sm:p-24">
                        <BasicInfoTab contacts={data?.contacts} deletedData={deletedData} setDeletedData={setDeletedData} />
                    </div>
                }
                scroll={isMobile ? 'normal' : 'content'}
            />
        </FormProvider>
    );
}

export default withReducer('userProfileApp', reducer)(EditProfile);
