import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const NotesApp = lazy(() => import('./DaasTestApp'));

const NotesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/daastest',
      element: <NotesApp />,
      children: [
        {
          path: ':filter',
          element: <NotesApp />,
          children: [
            {
              path: ':id',
              element: <NotesApp />,
            },
          ],
        },
      ],
    },
  ],
};

export default NotesAppConfig;
