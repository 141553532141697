import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const Msg = lazy(() => import('./msg/Msg'));
const Msgs = lazy(() => import('./msgs/Msgs'));

const MsgAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/msgs',
      element: <Msgs />,
    },
    {
      path: 'apps/msgs/:msgId/*',
      element: <Msg />,
    },
  ],
};

export default MsgAppConfig;
