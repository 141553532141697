import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const Cronjob = lazy(() => import('./cronjob/CronJobs'));
const Cronjobs = lazy(() => import('./cronjobs/CronjobsList'));

const CronjobsAppConfig = {
    settings: {
        layout: {},
    },
    auth: authRoles.admin,
    routes: [
        {
            path: 'apps/cronjobs',
            element: <Cronjobs />,
        },
        {
            path: 'apps/cronjobs/:agency_key/*',
            element: <Cronjob />,
        },
    ],
};

export default CronjobsAppConfig;
