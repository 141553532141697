import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import { useDispatch } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import { showMessage } from "app/store/fuse/messageSlice";
import FusePageCarded from "@fuse/core/FusePageCarded";
import { useThemeMediaQuery } from "@fuse/hooks";
import { useLocation } from "react-router-dom";
import TdmDetailPageHeader from "./TdmDetailPageHeader";
import DemoTdmRowPageContent from "./DemoTdmRowPageContent";
import withReducer from "app/store/withReducer";
import reducer from "../../store";

function DemoTdmRowPage() {
    const [loading, setLoading] = useState(true);
    const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
    const userData = useSelector(selectUser);

    const dispatch = useDispatch();

    const location = useLocation();
    const { TdmRowData } = location.state || {};


    // useEffect(() => {
    //     const body = {
    //         role: userData?.role || null,
    //         user_id: userData?.id || null
    //     };
    //     dispatch(getTdmFiles(body)).then((res) => {
    //         if (res?.payload?.data?.length > 0) {
    //             setLoading(false)
    //             dispatch(showMessage({ message: "TDM Data Found Successfully", variant: "success" }))
    //         } else {
    //             setLoading(false)
    //             dispatch(showMessage({ message: "TDM Data Not Found", variant: "warning" }))
    //         }
    //     });
    // }, [userData?.role, userData?.id]);

    // if (loading) {
    //     return (
    //         <div className="flex items-center justify-center h-full">
    //             <FuseLoading />
    //         </div>
    //     );
    // }

    return (
        <FusePageCarded
            header={
                <TdmDetailPageHeader />
            }
            content={
                <DemoTdmRowPageContent />
            }
            scroll={isMobile ? "normal" : "page"}
        />
    );
}
export default withReducer("transitDataManagerApp", reducer)(DemoTdmRowPage);
