import React, { useEffect, useState } from 'react';
import { Dialog, ListItem, Checkbox, Button, ListItemText, DialogActions, DialogContent, Tooltip, IconButton, ButtonGroup, Input, Snackbar, Alert, Typography } from '@mui/material';
import { FixedSizeList } from 'react-window';
import FilterListIcon from '@mui/icons-material/FilterList';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Box } from '@mui/system';
import { motion } from 'framer-motion';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RuleIcon from '@mui/icons-material/Rule';
import { useSelector } from 'react-redux';
import { selectStopsByTripData } from '../../store/TransitSlice';

const StopSelector = ({ setSelectedStopsId, selectedTripId, selectedTripDirection, unFilteredStops, setStopNames, selectedstopsId }) => {
    const [open, setOpen] = useState(false);
    const [selectedStops, setSelectedStops] = useState([]);
    const [selectedStopsInfo, setSelectedStopsInfo] = useState([]);
    const [firstStop, setFirstStop] = useState(null);
    const [stops, setStops] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [mode, setMode] = useState('manual');
    const [snackMessage, setSnackMessage] = useState(null);
    const [snackVariant, setSnackVariant] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [removeStops, setRemoveStops] = useState(false);
    const [tempSelectedStops, setTempSelectedStops] = useState([]);
    const [tempSelectedStopsInfo, setTempSelectedStopsInfo] = useState([]);

    const handleSelectStop = (index) => {
        if (mode === 'manual') {
            setTempSelectedStops(prevSelected =>
                prevSelected.includes(stops[index].id)
                    ? prevSelected.filter(id => id !== stops[index].id)
                    : [...prevSelected, stops[index].id]
            );
            setTempSelectedStopsInfo(prevSelected =>
                prevSelected.includes(stops[index].name)
                    ? prevSelected.filter(name => name !== stops[index].name)
                    : [...prevSelected, stops[index].name]
            );
        } else {
            if (firstStop === null) {
                setFirstStop(index);
                setTempSelectedStops([stops[index].id]);
                setTempSelectedStopsInfo([stops[index].name]);
            } else {
                const start = Math.min(firstStop, index);
                const end = Math.max(firstStop, index);
                const newSelectedStops = stops.slice(start, end + 1).map(stop => stop.id);
                const newSelectedStopsInfo = stops.slice(start, end + 1).map(stop => stop.name);
                setTempSelectedStops(newSelectedStops);
                setTempSelectedStopsInfo(newSelectedStopsInfo);
                setFirstStop(null);
            }
        }
    };

    const handleSnackbar = (variant, message) => {
        setSnackVariant(variant);
        setSnackMessage(message);
        setSnackOpen(true);
    };

    const handleClearSelection = () => {
        setTempSelectedStops([]);
        setTempSelectedStopsInfo([]);
        setFirstStop(null);
        setRemoveStops(true);
    };

    const renderRow = ({ index, style }) => (
        <ListItem button style={style} key={stops[index].id} onClick={() => handleSelectStop(index)}>
            <Checkbox
                edge="start"
                checked={tempSelectedStops.includes(stops[index].id)}
                tabIndex={-1}
                disableRipple
            />
            <ListItemText primary={stops[index].name} />
        </ListItem>
    );

    const handleClose = () => {
        setOpen(false);
        setFirstStop(null);
        setMode('manual');
        setSearchText('');
        setTempSelectedStops(selectedStops);
        setTempSelectedStopsInfo(selectedStopsInfo);
        setRemoveStops(false);
    };

    const handleOpen = () => {
        // Initialize tempSelectedStops and tempSelectedStopsInfo with selectedStopsId and selectedStopsInfo
        setTempSelectedStops(selectedstopsId);
        setTempSelectedStopsInfo(selectedstopsId.map(id => stops.find(stop => stop.id === id)?.name || ''));
        setOpen(true);
    };

    const handleConfirm = () => {
        if (removeStops) {
            setSelectedStopsId([]);
            setStopNames([]);
            setSelectedStops([]);
            setSelectedStopsInfo([]);
        } else {
            setSelectedStopsId(tempSelectedStops);
            setStopNames(tempSelectedStopsInfo);
            setSelectedStops(tempSelectedStops);
            setSelectedStopsInfo(tempSelectedStopsInfo);
        }
        setRemoveStops(false);
        setOpen(false);
    };

    const handleButtonClick = (mode) => {
        setMode(mode);
    };

    const handleClickFilter = () => {
        if (!selectedTripDirection) {
            handleSnackbar('warning', 'Please Select Direction');
        } else if (!selectedTripId) {
            handleSnackbar('warning', 'Please Select Trip Id');
        } else {
            handleOpen();
        }
    };

    useEffect(() => {
        let filterData = [];
        if (unFilteredStops?.length > 0) {
            filterData = unFilteredStops.map(stop => ({
                id: stop?.stop_id,
                name: `${stop?.stop_id}_${stop?.stop_name}`
            }));
        }

        if (searchText !== "") {
            filterData = filterData.filter(stop =>
                stop?.id?.includes(searchText) || stop?.name?.toLowerCase()?.includes(searchText)
            );
        }
        setStops(filterData);
    }, [searchText, unFilteredStops]);

    useEffect(() => {
        if (selectedstopsId?.length > 0) {
            setTempSelectedStops(selectedstopsId);
            setTempSelectedStopsInfo(selectedstopsId?.map(id => stops.find(stop => stop?.id === id)?.name || ''));
        }
    }, [selectedstopsId, stops]);

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };
    return (
        <div>
            <Tooltip title="Filter Stops">
                <IconButton onClick={handleClickFilter}>
                    <FilterListIcon color="secondary" fontSize="large" />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogContent>
                    <div className="flex sm:flex-row flex-col items-center -mx-8 gap-10 pb-10">
                        <Box
                            component={motion.div}
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                            className="flex flex-1 w-full sm:w-auto items-center pl-16 mx-8 border-1 rounded-full"
                        >
                            <FuseSvgIcon color="action" size={20}>
                                heroicons-outline:search
                            </FuseSvgIcon>

                            <Input
                                placeholder="Search"
                                className="flex flex-1 px-16"
                                disableUnderline
                                fullWidth
                                value={searchText}
                                inputProps={{ "aria-label": "Search" }}
                                onChange={(ev) => setSearchText(ev?.target?.value || "")}
                            />
                        </Box>
                        <ButtonGroup variant="outlined" color="primary" className="mb-3">
                            <Tooltip title="Manual Mode">
                                <Button
                                    onClick={() => handleButtonClick('manual')}
                                    style={{ backgroundColor: mode === 'manual' ? 'rgb(62 27 26)' : 'inherit' }}
                                >
                                    <RuleIcon />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Auto Mode">
                                <Button
                                    onClick={() => handleButtonClick('auto')}
                                    style={{ backgroundColor: mode === 'auto' ? 'rgb(62 27 26)' : 'inherit' }}
                                >
                                    <CheckBoxIcon />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Clear Stops">
                                <Button onClick={handleClearSelection} color="secondary"><NotInterestedIcon /></Button>
                            </Tooltip>
                        </ButtonGroup>
                    </div>
                    {
                        stops.length <= 0 ? (
                            <div className="flex flex-wrap m-8 justify-center items-center h-56">
                                <Typography className="font-medium text-lg">
                                    There is no Stop
                                </Typography>
                            </div>
                        ) : (
                            <FixedSizeList
                                height={400}
                                width="100%"
                                itemSize={46}
                                itemCount={stops.length}
                            >
                                {renderRow}
                            </FixedSizeList>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackOpen}
                autoHideDuration={2000}
                onClose={handleCloseSnack}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleCloseSnack} variant="filled" severity={snackVariant} >
                    {snackMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default StopSelector;
