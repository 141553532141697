import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import userInterfaceConfigs from '../main/user-interface/UserInterfaceConfigs';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import dashboardsConfigs from '../main/dashboards/dashboardsConfigs';
import appsConfigs from '../main/apps/appsConfigs';
import pagesConfigs from '../main/pages/pagesConfigs';
import authRoleExamplesConfigs from '../main/auth/authRoleExamplesConfigs';
import DocumentationConfig from '../main/documentation/DocumentationConfig';
import dashboardsProviderConfigs from '../main/dashboards-provider/dashboardsProviderConfigs';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';


const routeConfigs = [
  ...appsConfigs,
  ...dashboardsConfigs,
  ...dashboardsProviderConfigs,
  ...pagesConfigs,
  ...authRoleExamplesConfigs,
  ...userInterfaceConfigs,
  DocumentationConfig,
  SignOutConfig,
  SignInConfig,
  SignUpConfig,
];


const AuthBasedRedirect = () => {
  const navigate = useNavigate();
  const auth = useSelector(selectUser);

  useEffect(() => {
    let defaultArray = settingsConfig.defaultAuth;
    let agencyAuth = settingsConfig.agencyAdmin;
    let providerAuth = settingsConfig.providerAdmin;
    if (defaultArray.includes(auth?.role)) {
      navigate('dashboard');
    } else if (agencyAuth.includes(auth?.role)) {
      navigate('dashboards/agency');
    } else if(providerAuth.includes(auth?.role)){
      navigate('dashboards/provider');
    } else {
      navigate('sign-in'); // or wherever you want unauthenticated users to go
    }
  }, [navigate, auth]);

  return null;
};

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <AuthBasedRedirect />,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '*',
    element: <Navigate to="pages/error/404" />,
  },
];

export default routes;
