import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import FuseUtils from '@fuse/utils';
import firebaseService from 'src/app/services/firebaseService';

export const getApksFiles = createAsyncThunk('apkKeysApp/apkKeys/getapkfiles',
    async dispatch => {
        firebaseService.init(success => {
            if (!success) {
                resolve();
            }
        });

        return firebaseService
            .getApkFiles()
            .then((val) => {
                return val;
            })
            .catch(error => {
                console.log('Error--->', error)
                dispatch(false)
            });
    }
);

const apkFilesAdapter = createEntityAdapter({});


export const getdatafiles =
    apkFilesAdapter.getSelectors((state) => state.apkKeysApp.apkfiles);

export const selectApkFiles = ({ apkKeysApp }) => apkKeysApp.apkFilesSlice;

const apkFilesSlice = createSlice({
    name: 'apkKeysApp/apkfiles',
    initialState: apkFilesAdapter.getInitialState({}),
    reducers: {
    },
    extraReducers: {
        [getApksFiles.fulfilled]: (state, action) => {
            const items = action.payload;
            apkFilesAdapter.setAll(state, items);

        },
    },
});

export default apkFilesSlice.reducer;
