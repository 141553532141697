import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const Gtfs = lazy(() => import('./gtfs/Gtfs'));
const GtfsList = lazy(() => import('./gtfsList/GtfsList'));

const GtfsAppConfig = {
    settings: {
        layout: {},
    },
    auth: authRoles.user,
    routes: [
        {
            path: 'apps/gtfsList',
            element: <GtfsList />,
        },
        {
            path: 'apps/gtfsList/gtfs/:gtfsId/*',
            element: <Gtfs />,
        },
    ],
};

export default GtfsAppConfig;
