import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getProviderData = createAsyncThunk('userProfileApp/provider/get/profileData', async (provider_id) => {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/Provider/profile/get/${provider_id}`);
    const data = await response?.data?.data;
    return data;
});

export const updateProviderImage = createAsyncThunk(
    'userProfileApp/provider/image/update',
    async ({ id, image_url }) => {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/User/profile/image/update/${id}`, { image_url });
        return response;
    }
);

// export const updateProviderData = createAsyncThunk(
//     'userProfileApp/provider/update/profileData',
//     async ({ provider_id, payloadBody }) => {
//         const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/Provider/profile/update/${provider_id}`, payloadBody);
//         return response;
//     }
// );

const providerProfileSlice = createSlice({
    name: 'userProfileApp/provider',
    initialState: {},
    reducers: {},
    extraReducers: {
        [getProviderData.pending]: (state, action) => null,
        [getProviderData.fulfilled]: (state, action) => action.payload,
        [updateProviderImage.pending]: (state, action) => null,
        [updateProviderImage.fulfilled]: (state, action) => state,
        // [updateProviderData.pending]: (state, action) => null,
        // [updateProviderData.fulfilled]: (state, action) => action.payload,
    },
});

export const selectProviderData = ({ userProfileApp }) => userProfileApp?.providerProfileSlice

export default providerProfileSlice.reducer;
