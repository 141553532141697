import { lazy } from 'react';
import { authRoles } from 'src/app/auth';
import DemoTdmRowPage from './tabs/details/DemoTdmRowPage';

const TransitDataManagerApp = lazy(() => import('./TransitDataManagerApp'));

const TransitDataManagerAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.adminProvider,
  routes: [
    {
      path: 'apps/transitDataManager/demoTDM',
      element: <TransitDataManagerApp />,
    }
    ,
    {
      path: 'apps/transitDataManager/demoTDM/details/:id',
      element: <DemoTdmRowPage />,
    }
  ],
};

export default TransitDataManagerAppConfig;
