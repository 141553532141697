import _ from '@lodash';

const ContactModel = (data) =>
  _.defaults(data || {}, {
    provider: null,
    agency: null,
    packageManager: null,
    apks: [],
  });

export default ContactModel;
