import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';

const Ad = lazy(() => import('./ad/Ad'));
const Ads = lazy(() => import('./ads/Ads'));

const AdAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.commonuser,
  routes: [
    {
      path: 'apps/ads',
      element: <Ads />,
    },
    {
      path: 'apps/ads/:adId/*',
      element: <Ad />,
    },
  ],
};

export default AdAppConfig;
