import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Button from '@mui/material/Button';
import { forwardRef, useState } from 'react';
import clsx from 'clsx';
import PhoneNumberInput from './PhoneNumberInput';
import { useEffect } from 'react';

const PhoneNumberSelector = forwardRef(
    ({ value, onChange, className, setDeletedData, deletedData }, ref) => {
        const [filteredPageData, setFilteredPageData] = useState(value);
        useEffect(() => {
            setFilteredPageData(value);
        }, [value]);
        return (
            <div className={clsx("w-full", className)} ref={ref}>
                {filteredPageData?.map((item, index) => (
                    <PhoneNumberInput
                        value={item}
                        key={index}
                        onChange={(val) => {
                            const newData = filteredPageData.map((_item, _index) =>
                                index === _index ? val : _item
                            );
                            onChange(newData);
                            setFilteredPageData(newData);
                        }}
                        onRemove={() => {
                            const updatedData = filteredPageData.filter((_item, _index) => index !== _index);
                            const deleteData = filteredPageData.find((_item, _index) => _index === index);
                            const deletedDataObj = {
                                ...deleteData,
                                is_deleted: true,
                            };
                            setDeletedData([...deletedData, deletedDataObj]);
                            onChange([...updatedData]);
                            setFilteredPageData([...updatedData]);
                        }}
                        hideRemove={filteredPageData.length === 1}
                    />
                ))}
                <div className="flex justify-between">
                    <Button
                        className="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer"
                        onClick={() => {
                            onChange([...value, { phone: '', name: '', designation: '' }])
                            setFilteredPageData([...filteredPageData, { phone: '', name: '', designation: '' }])
                        }}
                    >
                        <FuseSvgIcon size={20}>heroicons-solid:plus</FuseSvgIcon>

                        <span className="ml-8 font-medium text-secondary group-hover:underline">
                            Add a Contact
                        </span>
                    </Button>
                </div>
            </div>
        );
    }
);

export default PhoneNumberSelector;

