import { combineReducers } from '@reduxjs/toolkit';
import userProfileSlice from './profileSlice';
import providerProfileSlice from './ProviderSlice';
import DaasConfigSlice from './DaasConfigSlice';

const reducer = combineReducers({
    userProfileSlice,
    providerProfileSlice,
    DaasConfigSlice
});

export default reducer;
